import { Component, OnInit, ViewChild } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MainService } from 'src/app/services/main.service';
import{PaymentService} from 'src/app/services/payment.service' 
declare let Instamojo: any;
const URL ='https://serverengg.oceansofttech.net/api/zion/UPLOAD';
@Component({
  selector: 'app-member-form',
  templateUrl: './member-form.component.html',
  styleUrls: ['./member-form.component.css']
})
export class MemberFormComponent implements OnInit {
  DistrictList:any;
  CategoryList:any;
  StateList:any;
  CasteList:any;
  Title:string="Member Form"
  ImageResult: any;
  Description:string;
  Submitted = false;
  District:number;
  Id:number;
  AmountId:number;
  PaymentList:any;
  Url:any;
  Status:string;
  memberform:FormGroup;
  Token: string = localStorage.getItem('auth_token');
  // Parameter:any={ url: URL, itemAlias: 'photo', authToken: `Bearer ${this.Token}`, additionalParameter: { 'PkSystemUploadDocSetting': '{"IsCipher":"1","ApplicationId":"2","PkSystemUploadDocSetting":1}' } };
  Str: string;
   /* Uploader For AadharCard start */
   @ViewChild('fileInput') fileInput;
   public uploader: FileUploader = new FileUploader({ url: URL, itemAlias: 'photo', authToken: `Bearer ${this.Token}`, additionalParameter: { 'PkSystemUploadDocSetting': '{"IsCipher":"1","ApplicationId":"2","PkSystemUploadDocSetting":1}' } });
   /*  Uploader For AadharCard End */
  /*  Uploader For photo start */
   public uploader1: FileUploader = new FileUploader({ url: URL, itemAlias: 'photo', authToken: `Bearer ${this.Token}`, additionalParameter: { 'PkSystemUploadDocSetting': '{"IsCipher":"1","ApplicationId":"2","PkSystemUploadDocSetting":1}' } });
  /*  Uploader For photo End */
   ImageResult1: string;

  constructor(private mainService:MainService,private fb:FormBuilder,private payservice:PaymentService) { }
  

  ngOnInit() {
   this.memberform=this.fb.group({
     Pkmember:['',Validators.required],
     Name:['',Validators.required],
     Address:['',Validators.required],
     Mobno:['',[Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
     Email:['',[Validators.required,Validators.email]],
     FirstAppointmentDate:['',Validators.required],
     FkCaste:['',Validators.required],  
     Category:['',Validators.required],
     Jobplace:['',Validators.required],
     State:['',Validators.required],
     District:['',Validators.required],
     Post:['',Validators.required],
     CasteValidity:['',Validators.required],
     ValidityDate:['',Validators.required],
     extraFlag:['',Validators.required],
     JobLoss:['',Validators.required],
     LeftSTCaste:['',Validators.required],
     othercategory:['',Validators.required],
     EmployeeOfWhichDeparment:['',Validators.required],
     /* Newly Added */
     CentralState:['',Validators.required],
     CentralDistrict:['',Validators.required],
     person1:['',Validators.required],
     person2:['',Validators.required],
      /* Newly Added */
     CasteCertificateDate:['',Validators.required],
     AadharNo:['',Validators.required],
     AdharPhotoPath:['',Validators.required],
     PhotoPath:['',Validators.required],
     imageUpload:[''],
     FileName:[''],
    
   })
    
   /* Image Uploader Start */
     this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
     this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
       this.Str = response;
       this.ImageResult = this.Str.replace("\"", "");
       this.Str = response;
       this.ImageResult1 = this.Str.replace("\"", "");
       this.ImageResult1 = this.ImageResult1.replace("{'Result': '1','FileName':'", "");
       this.ImageResult1 = this.ImageResult1.replace(",'}", "");
       this.memberform.controls['AdharPhotoPath'].setValue(this.ImageResult1);
     }
       this.uploader1.onAfterAddingFile = (file) => { file.withCredentials = false; };
       this.uploader1.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
         this.Str = response;
         this.ImageResult = this.Str.replace("\"", "");
         this.Str = response;
         this.ImageResult1 = this.Str.replace("\"", "");
         this.ImageResult1 = this.ImageResult1.replace("{'Result': '1','FileName':'", "");
         this.ImageResult1 = this.ImageResult1.replace(",'}", "");
        this.memberform.controls['PhotoPath'].setValue(this.ImageResult1);
       }
   /*Image Uploader  End */
      this. State_DDL();
      this. Amount_DDL_Select();
   }
   State_DDL(){
     var Moobj: any = {};
     Moobj.Pid ='235ddd46-8db7-4fba-96b2-3ea465234e61';
    this.mainService.TwoMethod(Moobj).then(value => {
    this.StateList = value;
    this.StateList = this.StateList.table;
  });
  
   }
   District_DDL_Select() {
    var Moobj: any = {};
    Moobj.Pid ='d343c4db-01f0-46a9-a604-c4395e880664';
     Moobj.FkState=this.memberform.get('State').value;
     this.mainService.TwoMethod(Moobj).then(value => {
      this.DistrictList = value;
      this.DistrictList = this.DistrictList.table;
    });
   }
   changeDistrict(e) {
  // console.log(JSON.stringify(this.memberform.get('District').value)+"this is id");
    this.District=this.memberform.get('District').value
   }
   Amount_DDL_Select() {
    var Moobj: any = {};
    Moobj.Pid ='66cdf7be-3869-48f0-9dcc-1ad28940e24a';
    this.mainService.TwoMethod(Moobj).then(value => {
    this.CategoryList = value;
    this.CategoryList = this.CategoryList.table;
    this.Description=this.CategoryList[0].description;
    this.AmountId=this.CategoryList[0].pkAmount;
    localStorage.setItem('Amount',this.CategoryList[0].amount)
    });
   }
   Member_Insert(){
    // stop here if form is invalid
    if (this.memberform.get('Name').invalid||this.memberform.get('Address').invalid||
          this.memberform.get('Mobno').invalid||this.memberform.get('Email').invalid ){
          this.validateAllFormFields(this.memberform);
    }
    else{
  var Moobj: any = {};
  Moobj.Pid ='0989b3c2-aeed-4dc5-86e2-1a98bd05abff';
  Moobj.Name=this.memberform.value.Name;
  localStorage.setItem('MemberName',Moobj.Name)
  Moobj.Address=this.memberform.value.Address;
  Moobj.MobNo=this.memberform.value.Mobno;
  Moobj.Email=this.memberform.value.Email;
  localStorage.setItem('MemberEmail',Moobj.Email)
  Moobj.Caste=this.memberform.value.FkCaste;
  Moobj.Category=this.memberform.value.Category;
  Moobj.JobPlace=this.memberform.value.Jobplace;
  Moobj.FkDistrict=this.District   //this.memberform.value.District;
  Moobj.Post=this.memberform.value.Post;
  Moobj.FirstAppointmentDate=this.memberform.value.FirstAppointmentDate;
  Moobj.ServiceCategory=this.memberform.value.EmployeeOfWhichDeparment
  Moobj.CentralState=this.memberform.value.CentralState;
  Moobj.CentralDistrict=this.memberform.value.CentralDistrict;
  Moobj.RefPersonOne=this.memberform.value.person1;
  Moobj.RefPersonTwo=this.memberform.value.person2;
  Moobj.Validityflag=this.memberform.value.CasteValidity;
  Moobj.ValidityDate=this.memberform.value.ValidityDate;
  Moobj.AreYouExtraFlag=this.memberform.value.extraFlag;
  Moobj.JobLossDueToValidityFlag=this.memberform.value.JobLoss;
  Moobj.AreYouSTFlag=this.memberform.value.LeftSTCaste;
  Moobj.OtherCategory=this.memberform.value.othercategory;
  Moobj.OtherCasteCertificateDate=this.memberform.value.CasteCertificateDate;
  Moobj.AadharCardPhotoPath=this.memberform.value.AdharPhotoPath;
  Moobj.PhotoPath=this.memberform.value.PhotoPath;
  this.mainService.TwoMethod(Moobj).then(value => {
    this.CategoryList = value;
    this.CategoryList = this.CategoryList.table;
    localStorage.setItem('Pkmember',this.CategoryList[0].column1)
    setTimeout(() => {
      if(this.CategoryList[0].column1>0){
        this.Pay();
        }
        else{
          alert("Please insert correct Information")
        }
    }, 1000);
    });
    this.Reset();
  }
   }
/* For जात  प्रमाणपत्र वैध/अवैध  */
   CastValidity(){
  if(this.memberform.value.CasteValidity=="1"){
    document.getElementById('ValidityDate').className="show";
  }
  else{
    document.getElementById('ValidityDate').className="hide";
  }
   }
/* For आपण ST प्रवर्गातील दावा सोडला काय */
   LeftSTCaste(){
  if(this.memberform.value.LeftSTCaste=="1"){
    document.getElementById('othercategory').className="show";
    document.getElementById('CasteCertificateDate').className="show";
  }
  else{
    document.getElementById('othercategory').className="hide";
    document.getElementById('CasteCertificateDate').className="hide";
  }
   }
/* if You are Employee of Central Goverment */
   ServiceCategory(){
  if(this.memberform.value.EmployeeOfWhichDeparment=="Central"){
    document.getElementById('CentralState').className="show";
    document.getElementById('CentralDistrict').className="show";
  }
  else{
    document.getElementById('CentralState').className="hide";
    document.getElementById('CentralDistrict').className="hide";
  }
   }
   Pay(){
    let Pkmember=localStorage.getItem('Pkmember');
    // let MemberName=localStorage.getItem('MemberName');
    // let MemberEmail=localStorage.getItem('MemberEmail');
    var Moobj:any={};
    Moobj.send_email=true;
    Moobj.phone=this.memberform.value.Mobno;
    Moobj.email=localStorage.getItem('MemberEmail');
    Moobj.buyer_name=localStorage.getItem('MemberName');
    Moobj.purpose= "ID:" + Pkmember  ;
    Moobj.amount=localStorage.getItem('Amount');
    Moobj.webhook='https://ofroh.org/Invoice';
    this.payservice.OneMethod(Moobj).then(value=>{
      this.CategoryList=value;
      this.Url=this.CategoryList.payment_request.longurl;
      this.Id=this.CategoryList.payment_request.id;
      this.Status=this.CategoryList.payment_request.status;
      localStorage.setItem('url',this.Url);
    
      var moobj:any={}
      moobj.Pid='36748bd1-da77-42c3-a9ca-235944a4396c';
      moobj.FkMember=localStorage.getItem('Pkmember');
      moobj.TypeofPayment=this.AmountId;
      moobj.PaymentDescription=Moobj.purpose;
      moobj.Amount=localStorage.getItem('Amount');
      moobj.PDate=new Date();
      moobj.TransactionID=this.Id;
      moobj.PStatus= this.Status;
      this.mainService.TwoMethod(moobj).then(value => {
        this.PaymentList = value;
        this.PaymentList = this.PaymentList.table;
      })
      window.open(this.Url);
    });
   }
   Reset(){
   this.memberform.reset();
   }
   isFieldValid(field: string) {
    return !this.memberform.get(field).valid && this.memberform.get(field).touched;
   }
   displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
   }
   validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
   }
}
