<!-- <app-home-title [title]="Title" [message]="Title" [home]="home"></app-home-title> -->
<app-sub-banner [title]="Title"></app-sub-banner>
<!-- Start Speaker Area -->

<!-- End Speaker Area -->

<section class="speaker-section pb">
    <div class="container">
        
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6" *ngFor="let data of GallaryList"  >
                <div class="single-team">
                    <div class="image">
                        <img src={{data.imagepath}} alt="image">
                    
                        <!-- <ul class="social">
                            <li>
                                <a href="#" target="_blank">
                                    <i class="bx bxl-facebook"></i>
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_blank">
                                    <i class="bx bxl-twitter"></i>
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_blank">
                                    <i class="bx bxl-linkedin"></i>
                                </a>
                            </li>
                        </ul> -->

                        <!-- <div class="content">
                            <h3>{{data.galleryImagesTitle}}</h3>
                          
                        </div> -->

                        <!-- <div class="speaker-shape">
                            <img src="assets/img/team/shape.png" alt="image">
                        </div> -->
                    </div>
                </div>
            </div>

        
        </div>
    </div>
</section>