import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-payment-dashboard',
  templateUrl: './payment-dashboard.component.html',
  styleUrls: ['./payment-dashboard.component.css']
})
export class PaymentDashboardComponent implements OnInit {

  constructor(private mainService:MainService ) { }
  eventList:any;
  status:string;

  ngOnInit() {
     this.PaymentInfo_Select_Display();
     this.Status;
  }

  PaymentInfo_Select(e){
    //alert(e.target.options[e.target.selectedIndex].value)
    var Moobj:any = {}; 
    Moobj.Pid ='021eef96-f201-4399-8829-7577fbc2a594';
    Moobj.PStatus=e.target.options[e.target.selectedIndex].value;
    this.status=e.target.options[e.target.selectedIndex].value;
    this.mainService.OneMethod(Moobj).then(value=>{
    this.eventList=value;
    this.eventList= this.eventList.table;
    console.log(this.eventList)
   });
  }
  PaymentInfo_Select_Display(){
      var Moobj:any = {}; 
    Moobj.Pid ='021eef96-f201-4399-8829-7577fbc2a594';
    Moobj.PStatus=this.status
    this.mainService.OneMethod(Moobj).then(value=>{
    this.eventList=value;
    this.eventList= this.eventList.table;
    console.log(this.eventList)
    })
  }
  Status:any=[
    {name:"All Event Status",value:""},{name:"Completed",value:"Completed"},{name:"Pending",value:"Pending"}
  ]
}
