import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-title',
  templateUrl: './home-title.component.html',
  styleUrls: ['./home-title.component.css']
})
export class HomeTitleComponent implements OnInit {
  @Input() title:string;
  @Input() message:string;
  @Input() home:string;

  constructor() { }

  ngOnInit(): void {
  }

}
