import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot,Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthrizationService } from './auth.service';
// auth.guard.ts   
@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthrizationService,private router: Router) {}

  // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
  //   return this.authService.isAuthenticated();
  // }
  canActivate() {

    if (!this.authService.isLoggedIn())
    {
       this.router.navigate(['/login']);
       return false;
    }

    return true;
  }
}
