   <!-- Start Page Title Area -->
   <!-- <div class="page-title-area item-bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>{{title}}</h2>
                    <ul >
                        <li class="styllist"><a >{{home}}</a></li>
                        <li class="styllist">{{message}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Page Title Area -->

   <!-- Start Banner Area Nine -->
   <div class="main-banner-nine">
    <div class="d-table">
        <div class="d-table-cell">
            <div >
                <div class="main-banner-content">
                    <h2 style="font-size: 45px; height: 40px; color:white ">{{title}}</h2>
                 </div>   
             </div>
        </div>
     </div>
    </div>             