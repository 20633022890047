  <!-- Start Fun Facts Section -->
  <!-- <section class="fun-facts-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span class="odometer" data-count="820">00</span>
                        <span class="sign-icon">+</span>
                    </h3>
                    <p>Total Events</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span class="odometer" data-count="150">00</span>
                        <span class="sign-icon">+</span>
                    </h3>
                    <p>Total Experts</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span class="odometer" data-count="20">00</span>
                        <span class="sign-icon">+</span>
                    </h3>
                    <p>Sponsors</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span class="odometer" data-count="999">00</span>
                        <span class="sign-icon">+</span>
                    </h3>
                    <p>Total Attendance</p>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Fun Facts Section -->

        <!-- Start Counter Section -->
        <section class="counter-area pt-100 pb-70">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-3 col-md-6 col-sm-6">
                        <div class="single-counter">
                            <h3>
                                <span class="odometer" data-count="40">2020</span>
                                <!-- <span class="sign-icon">+</span> -->
                            </h3>
                            <p>Establishment</p>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6 col-sm-6">
                        <div class="single-counter">
                            <h3>
                                <span class="odometer" data-count="100">5000</span>
                            </h3>
                            <p>No of Member</p>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6 col-sm-6">
                        <div class="single-counter">
                            <h3>
                                <span class="odometer" data-count="256">36</span>
                            </h3>
                            <p>No of Branches</p>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6 col-sm-6">
                        <div class="single-counter">
                            <h3>
                                <span class="odometer" data-count="102">00</span>
                            </h3>
                            <p>New Workshops</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="lines">
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
            </div>
        </section>
        <!-- End Counter Section -->