<!-- <div class="page-title-area item-bg-8">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>{{title}}</h2>
                </div>
            </div>
        </div>
    </div>
</div> -->

  <!-- Start Fun Facts Section -->
   <div class="page-title-area item-bg-8">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>{{title}}</h2>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Fun Facts Section -->
