import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MainService } from 'src/app/services/main.service';
import { FileUploader } from 'ng2-file-upload';
import * as XLSX from 'xlsx';
import { MatDatepickerModule } from '@angular/material/datepicker';
import * as moment from 'moment';
const URL = 'https://serverengg.oceansofttech.net/api/cipher/UPLOAD';
@Component({
  selector: 'app-opt-bemember',
  templateUrl: './opt-bemember.component.html',
  styleUrls: ['./opt-bemember.component.css']
})
export class OptBememberComponent implements OnInit {
  /*name of the excel-file which will be downloaded. */
  
  othercategorys: any;
  u: any;
  fileName = 'ExcelSheet.xlsx';
  public page = 1;
  public pageSize = 50;
  DistrictList: any;
  StateList: any;
  bememberList: any;
  SpResult: any;
  memberform: FormGroup;
  Title: any;
  Description: any;
  State: any;
  District: any;
  CentralState: any;
  CategoryList: any;
  AmountId: any;
  Url: any;
  ImageResult1: any;
  Token: string = localStorage.getItem('auth_token');
  str: string;
  ImageResult: any;
  castdropdownlist: any;
  username1: any;
  selectedSTValue: any;
  DatebyId: any;
  DatebyMemberId: any;
  hidebuttonsave: boolean = false;
  hidebuttonlist: boolean = false;
  hidebuttonexcel: boolean = true;
  tblListhide: boolean = true;
  /* Uploader For AadharCard start */
  @ViewChild('fileInput') fileInput;
  public uploader: FileUploader = new FileUploader({ url: URL, itemAlias: 'photo', authToken: `Bearer ${this.Token}`, additionalParameter: { 'PkSystemUploadDocSetting': '{"IsCipher":"1","ApplicationId":"2","PkSystemUploadDocSetting":1}' } });
  /*  Uploader For AadharCard End */
  /*  Uploader For photo start */
  public uploader1: FileUploader = new FileUploader({ url: URL, itemAlias: 'photo', authToken: `Bearer ${this.Token}`, additionalParameter: { 'PkSystemUploadDocSetting': '{"IsCipher":"1","ApplicationId":"2","PkSystemUploadDocSetting":1}' } });
  /*  Uploader For photo End */
  constructor(private mainService: MainService, private fb: FormBuilder) { }

  ngOnInit() {
    this.memberform = this.fb.group({
      Pkmember: [''],
      FkDistrict: [''],
      Name: ['', Validators.required],
      Address: ['', Validators.required],
      Mobno: ['', [Validators.required, Validators.maxLength(10), Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      Email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      FkCaste: [''],
      Designation: [''],
      Jobplace: [''],
      TypeOfService: [''],
      CentralState: [''],
      District: [''],
      Validityflag: [''],
      Typesofservices: [''],
      CasteValidityOfficeAddress: [''],
      AreYouExtra: [''],
      JobLossDueToValidity: [''],
      AreYouInSt: [''],
      otherCategory: [''],
      AdharPhotoPath: [''],
      PhotoPath: [''],
      ChequeNo: ['-'],
      ChequeDate: new FormControl(new Date()),
      ExtraDescription: ['-'],
      MemberDate: new FormControl(new Date()),
      InsertedDate: [''],
      InsertedBy: [''],
    });
    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
     // console.log('ImageUpload:uploaded:', item, status, response);
      this.str = response;
      this.ImageResult = this.str.replace("\"", "");
      this.str = response;
      this.ImageResult1 = this.str.replace("\"", "");
      this.ImageResult1 = this.ImageResult1.replace("{'Result': '1','FileName':'", "");
      this.ImageResult1 = this.ImageResult1.replace(",'}", "");
      this.memberform.controls['AdharPhotoPath'].setValue(this.ImageResult1);
    }
    this.uploader1.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.uploader1.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      //console.log('ImageUpload:uploaded:', item, status, response);
      this.str = response;
      this.ImageResult = this.str.replace("\"", "");
      this.str = response;
      this.ImageResult1 = this.str.replace("\"", "");
      this.ImageResult1 = this.ImageResult1.replace("{'Result': '1','FileName':'", "");
      this.ImageResult1 = this.ImageResult1.replace(",'}", "");
      this.memberform.controls['PhotoPath'].setValue(this.ImageResult1);
    }
    this.CastList();
    this.State_DDL();
    this.District_DDL_Select();
    this.getName();
  }
  //This Clear() create for Clear All Textbox values
  clear() {
    this.memberform = this.fb.group({
      Pkmember: [''],
      FkDistrict: [''],
      Name: [''],
      Address: [''],
      Mobno: [''],
      Email: [''],
      FkCaste: [''],
      Designation: [''],
      Jobplace: [''],
      TypeOfService: [''],
      CentralState: [''],
      District: [''],
      Validityflag: [''],
      Typesofservices: [''],
      CasteValidityOfficeAddress: [''],
      AreYouExtra: [''],
      JobLossDueToValidity: [''],
      AreYouInSt: [''],
      otherCategory: [''],
      AdharPhotoPath: [''],
      PhotoPath: [''],
      ChequeNo: ['-'],
      ChequeDate: new FormControl(new Date()),
      ExtraDescription: ['-'],
      MemberDate: new FormControl(new Date()),
      InsertedDate: [''],
      InsertedBy: [''],

    });
  }
  //This keyPressmobile() create for Enter (Prevent)only Digit in Textbox values
  keyPressmobile(event: any) {
    const pattern = /[0-9\+\-\ ]/;

    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
    //This Method 'onChangeDate()' -Create for Calendar on change date
    onChangeDate(event) {
      let setDate = moment(event).format('YYYY-MM-DD');
      this.DatebyId = setDate;
    }
    //This Method 'onChangeMemberDate()' -Create for Calendar on change date
    onChangeMemberDate(event) {
      let setDate = moment(event).format('YYYY-MM-DD');
      this.DatebyMemberId = setDate;
    }
  //This setReplyTypeValue() create for category Yes/No then->otherCategory disable()
  setReplyTypeValue() {

    this.selectedSTValue = this.memberform.value.AreYouInSt
    if (this.selectedSTValue == 1) {
      this.memberform.controls['otherCategory'].setValue("ST");
      this.memberform.controls['otherCategory'].disable();
    }
    else {
      this.memberform.controls['otherCategory'].reset();
      this.memberform.controls['otherCategory'].enable();
    }
  }
  getName() {
    this.username1 = localStorage.getItem("email");
    this.memberform.controls['InsertedBy'].setValue(this.username1);
  }
  /* CRUD operation Start */
  async Insert() {
    var MoObj: any = {}
    MoObj.Pid = "c397267c-8b81-4728-8548-9300a7b2d891";
    MoObj.Name = this.memberform.value.Name;
    MoObj.Address = this.memberform.value.Address;
    MoObj.MobNo = this.memberform.value.Mobno;
    MoObj.Email = this.memberform.value.Email;
    MoObj.FkCaste = this.memberform.value.FkCaste;
    MoObj.Designation = this.memberform.value.Designation;
    MoObj.JobPlace = this.memberform.value.Jobplace;
    var FkDis = localStorage.getItem('fkDistrict')
    MoObj.FkDistrict = FkDis
    this.memberform.value.District = FkDis;
    MoObj.Validityflag = this.memberform.value.Validityflag;
    MoObj.CentralState = this.memberform.value.Typesofservices;//
    MoObj.CasteValidityOfficeAdd = this.memberform.value.CasteValidityOfficeAddress;
    MoObj.AreYouExtra = this.memberform.value.AreYouExtra;
    MoObj.JobLossDueToValidity = this.memberform.value.JobLossDueToValidity;
    MoObj.AreYouInST = this.memberform.value.AreYouInSt;
    if (MoObj.AreYouInST == 1) { MoObj.OtherCategory = "ST"; } else { MoObj.OtherCategory = this.memberform.value.otherCategory; }
    MoObj.AadharCard = this.memberform.value.AdharPhotoPath;
    MoObj.Photo = this.memberform.value.PhotoPath;
    if (this.memberform.value.ChequeNo == "") { MoObj.ChequeNo = "-"; }
    else { MoObj.ChequeNo = this.memberform.value.ChequeNo; }
    MoObj.ChequeDate = this.memberform.value.ChequeDate;
    if (this.memberform.value.ExtraDescription == "") { MoObj.ExtraDescription = "-"; }
    else { MoObj.ExtraDescription = this.memberform.value.ExtraDescription; }
    MoObj.MemberDate = this.memberform.value.MemberDate;
    MoObj.InsertedBy = this.memberform.value.InsertedBy;
    this.mainService.OneMethod(MoObj).then(value => {
      this.SpResult = value;
      alert(JSON.stringify(this.SpResult.table[0].resultmessage));
    });

    let handlePromise = new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.bememberList);
      }, 2000)
    })
    await handlePromise
    window.location.reload();
  }
  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('tblList');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }
  list() {
    var MoObj: any = {}
    MoObj.Pid = "88b93eb1-68e0-4ab4-a995-9d533815af98";
    MoObj.FkDistrict = localStorage.getItem('fkDistrict')
    this.mainService.TwoMethod(MoObj).then(value => {
      this.SpResult = value;
      this.SpResult = this.SpResult.table
    });
    this.hidebuttonsave = true;
    this.hidebuttonlist = false;
    this.hidebuttonexcel = false;
    this.tblListhide = false;
  }
  realodPage() {
    window.location.reload();
 }
  username(username: any) {
    throw new Error('Method not implemented.');
  }
  State_DDL() {
    var Moobj: any = {};
    Moobj.Pid = '235ddd46-8db7-4fba-96b2-3ea465234e61';
    this.mainService.TwoMethod(Moobj).then(value => {
      this.StateList = value;
      this.StateList = this.StateList.table;
      let fkstate = localStorage.getItem('fkState')
      if (fkstate) {
        for (let i = 0; i < this.StateList.length; i++) {
          let pkState = this.StateList[i].pkState;
          if (pkState == fkstate) {
            let temp = this.StateList.filter(x => x.pkState == fkstate)
            this.State = temp
          }
        }
      }
      else {
        this.State = this.StateList;
      }
    });
  }
  District_DDL_Select() {
    var Moobj: any = {};
    Moobj.Pid = 'd343c4db-01f0-46a9-a604-c4395e880664';
    Moobj.FkState = localStorage.getItem('fkState')
    this.mainService.TwoMethod(Moobj).then(value => {
      this.DistrictList = value;
      this.DistrictList = this.DistrictList.table;
      let fkDistrict = localStorage.getItem('fkDistrict')
      let temp = this.DistrictList.filter(x => x.pkDistrict == fkDistrict)
      this.DistrictList = temp
    });
  }
  CastList() {
    var Moobj: any = {};
    Moobj.Pid = 'bbf40695-c322-4e72-86c6-50fccff6b2f6 ';
    this.mainService.OneMethod(Moobj).then(value => {
      this.castdropdownlist = value;
      this.castdropdownlist = this.castdropdownlist.table;
    });
  }
  changeDistrict(e) {
    this.District = this.memberform.get('District').value
  }
  Rowselected(u: any) {
    var pkState: any;
    this.hidebuttonsave = true;
    this.hidebuttonlist = true;
    this.hidebuttonexcel = true;
    this.tblListhide = true;
    
    // formname          FormControlName       response from database parameter
    this.memberform.controls['ChequeNo'].setValue(u.chequeNo);
    this.memberform.controls['ChequeDate'].setValue(u.chequeDate);
    this.memberform.controls['ExtraDescription'].setValue(u.extraDescription);
    this.memberform.controls['MemberDate'].setValue(u.memberDate);
    this.memberform.controls['Pkmember'].setValue(u.pkMemberForm);
    this.memberform.controls['Name'].setValue(u.name);
    this.memberform.controls['Address'].setValue(u.address);
    this.memberform.controls['Mobno'].setValue(u.mobNo);
    this.memberform.controls['Email'].setValue(u.email);
    this.memberform.controls['FkCaste'].setValue(u.fkCaste);
    this.memberform.controls['Designation'].setValue(u.designation);
    this.memberform.controls['Jobplace'].setValue(u.jobPlace);
    this.memberform.controls['CentralState'].setValue(u.pkState);
    this.memberform.controls['District'].setValue(u.fkDistrict);
    this.memberform.controls['Validityflag'].setValue(u.validityflag);
    this.memberform.controls['Typesofservices'].setValue(u.centralState);
    this.memberform.controls['CasteValidityOfficeAddress'].setValue(u.casteValidityOfficeAdd);
    this.memberform.controls['AreYouExtra'].setValue(u.areYouExtra);
    this.memberform.controls['JobLossDueToValidity'].setValue(u.jobLossDueToValidity);
    this.memberform.controls['AreYouInSt'].setValue(u.areYouInST);
    this.memberform.controls['otherCategory'].setValue(u.otherCategory);
    this.memberform.controls['AdharPhotoPath'].setValue(u.aadharCard);
    this.memberform.controls['PhotoPath'].setValue(u.photo);
    this.memberform.controls['InsertedBy'].setValue(u.insertedBy);
    var Moobj: any = {};
    Moobj.Pid = 'd343c4db-01f0-46a9-a604-c4395e880664';
    Moobj.FkState = u.pkState;
    this.mainService.OneMethod(Moobj).then(value => {
      this.DistrictList = value;
      this.DistrictList = this.DistrictList.table;
    });
   
    
    const scrollElem = document.querySelector('#mytop');
    scrollElem.scrollIntoView();
  }
  get f() { return this.memberform.controls; }
}