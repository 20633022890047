import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MainService } from 'src/app/services/main.service';
import { FileUploader } from 'ng2-file-upload';
import * as XLSX from 'xlsx';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';//no need
//for date picker
import { MatDatepickerModule } from '@angular/material/datepicker';
import * as moment from 'moment';

@Component({
  selector: 'app-select-by-memberdate',
  templateUrl: './select-by-memberdate.component.html',
  styleUrls: ['./select-by-memberdate.component.css']
})
export class SelectByMemberdateComponent implements OnInit {
  memberform: FormGroup;
  SpResult: any;
  memberList: any;
  constructor(private mainService: MainService, private fb: FormBuilder, private calendar: NgbCalendar) { }

  ngOnInit() {
    this.memberform=this.fb.group({
      ChequeDate: new FormControl(new Date()),
    });
  }
  getMember()
  {
    var MoObj: any = {}
    MoObj.Pid = "5237f8c1-8004-4a93-be80-8a5cbf198e46";
    MoObj.MemberDate = this.memberform.value.ChequeDate;
    //alert(JSON.stringify(MoObj.MemberDate))
    this.mainService.TwoMethod(MoObj).then(value => {
      this.SpResult = value;
      this.SpResult = this.SpResult.table
       console.log(JSON.stringify(this.SpResult))
      // alert(JSON.stringify(this.SpResult));
    });
  }
}