<section>
    <div class="container">
        <div class="Wrapper">
            <div class="register-form">
         <h3 style="color: black;text-align: center;font-style: normal;">Add Gallary Header</h3>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
   
     <form [formGroup]="GallaryHeaderform">
   
       <div class="form-group row">
         <label class="col-sm-3 col-form-label">ID</label>
         <div class="col-sm-7">
           <input  class="form-control" formControlName="PkHeader">
         </div>
       </div>
   
       <div class="form-group row">
         <label for="inputPassword" class="col-sm-3 col-form-label"> Header Name</label>
           <div class="col-sm-7">
             <ck-editor name="editor1" [(ngModel)]="editorValue" formControlName="Name"
             skin="moono-lisa" lang="en" [fullPage]="true"></ck-editor>
           </div>
       </div>
     
     
     
       <div class="row">
        <div class="col-sm-2"  >
                 <button type="submit" class="btn btn-primary"           
                          (click)="GalleryHeaderMaster_Insert()">Submit</button>
        </div>&nbsp;&nbsp;&nbsp;

       <div class="col-sm-2"  >
                  <button type="submit" class="btn btn-primary" 
                          (click)="GalleryHeaderMaster_Update()">update</button>
       </div>&nbsp;&nbsp;&nbsp;
       <div  class="col-sm-2" >
                    <button type="submit" class="btn btn-primary" 
                               (click)=" GalleryHeaderMaster_Delete()">Delete</button>
       </div>&nbsp;&nbsp;&nbsp;

       <div  class="col-sm-2" >
                    <button type="submit" class="btn btn-primary" 
                              (click)="GalleryHeaderMaster_Select()">List</button>
       </div>&nbsp;&nbsp;&nbsp;

       <div  class="col-sm-2" >
                      <button type="submit" class="btn btn-primary" 
                                (click)="Reset()">Reset</button>
        </div>&nbsp;&nbsp;&nbsp;
   </div>
   <!-- extra -->
   <!-- <div id="profileformStatus">{{GallaryHeaderform.dirty ? true:false}}</div> -->
   <!-- extra -->
     </form>
    </div>
  </div>
 </div>

</section>

<div class="container">    
    <div class="table-responsive">   
    <table class="table" id="tblList">
      <thead>
           <tr>
               <th>ID</th>
               <th> Name</th>
           </tr>
      </thead>
      <tbody>
           <tr *ngFor="let cmp of CategoryList;">
               <td (click)="RowSelected(cmp)">{{cmp.id}}</td>
               <td [innerHTML]="cmp.galleryHeader">{{cmp.galleryHeader}}</td>
           </tr> 
      </tbody>
    </table>
  </div>
  </div>


