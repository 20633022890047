import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators ,FormControl} from '@angular/forms';
import { Router } from '@angular/router';
// import { debug } from 'console';
import { AuthrizationService } from 'src/app/services/auth.service';
import { MainService } from 'src/app/services/main.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  @ViewChild('f',{static: false}) loginForm: NgForm;
  errors: string;
  private IsSuccess: boolean;
  submitted = false;
  title:string="Login"
  fkDistrict:any;
  fkState:any;
  username: any;
  SpResult: any;
  userdec:any;
  constructor(private authservice:AuthrizationService, private router:Router,private fb:FormBuilder, private mainService:MainService ) { }

  public form:FormGroup

  ngOnInit(){
    this.form = this.fb.group({
      email:['',[Validators.required,Validators.email]],
      password:['',[Validators.required,]]
     });
  }




  login(form){
   this.authservice.login(this.form.value.email,this.form.value.password)
    .subscribe(
      result =>{
        console.log(JSON.stringify(result))
        if(result.result==1){
          if(result.result==1)
          {
             var username :any ={};
             var userdec : any = localStorage.getItem('email');
             username.Pid="05104e95-8636-4298-96f1-5bccab959e9c";         
             username.UserName=userdec;
             this.mainService.TwoMethod(username).then(value=>{
              this.SpResult=value;
             console.log(this.SpResult);
              var fkDistrictVar: any={};
              fkDistrictVar=this.SpResult.table[0].fkDistrict;

              var fkState: any={};
              fkState=this.SpResult.table[0].fkState;
            
              if (fkDistrictVar>0)
              {
                  this.router.navigate(['/operator']);
                  localStorage.setItem('fkDistrict', fkDistrictVar);
                  localStorage.setItem('fkState', fkState);
              }
              else{
                this.router.navigate(['/dashboard']);
              }
              });
          }
        
         
           }
       
       },
          error =>{
                    this.form.controls['email'].setErrors({ 'incorrect': true});
                    this.form.controls['Password'].setErrors({ 'incorrect': true});
    });
   
  }
logout(){
  this.authservice.logout();
  this.router.navigate(['/home'])
 }
}
function fkDistrict(arg0: string, fkDistrict: any) {
  throw new Error('Function not implemented.');
}

function fkState(arg0: string, fkState: any) {
  throw new Error('Function not implemented.');
}

