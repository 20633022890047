import { Component, OnInit, ViewChild } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MainService } from 'src/app/services/main.service';
const URL ='https://hrtft.com/api/cipher/UPLOAD';
@Component({
  selector: 'app-image-gallary',
  templateUrl: './image-gallary.component.html',
  styleUrls: ['./image-gallary.component.css']
})
export class ImageGallaryComponent implements OnInit {
  ImageList:any;
  SpResult:any;
  CategoryList:any;
  GalleryHeaderList:any;
  ImageResult: any;
  Token: string = localStorage.getItem('auth_token');
   str: string;
   @ViewChild('fileInput') fileInput;
   public uploader: FileUploader = new FileUploader({ url: URL, itemAlias: 'photo', authToken: `Bearer ${this.Token}`, additionalParameter: { 'PkSystemUploadDocSetting': '{"IsCipher":"1","ApplicationId":"2008","PkSystemUploadDocSetting":1}' } });
  //  text: any;
   ImageResult1: string;

  constructor(private mainService:MainService,private fb:FormBuilder) { }
  Imageform:FormGroup;
  ngOnInit() {
     /* Form Initialization */
    this.Imageform=this.fb.group({
      PkImage:['',Validators.required],
      FkGallaryheader:['',Validators.required],
      Name:['',Validators.required],
      PhotoPath:['',Validators.required],
      imageUpload:[''],
      FileName:['']
    })
      /* Image Uploader */
    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      // console.log('ImageUpload:uploaded:', item, status, response);
      this.str = response;
      this.ImageResult = this.str.replace("\"", "");
      this.str = response;
      this.ImageResult1 = this.str.replace("\"", "");
      this.ImageResult1 = this.ImageResult1.replace("{'Result': '1','FileName':'", "");
      this.ImageResult1 = this.ImageResult1.replace(",'}", "");
      // console.log( this.ImageResult1)
      this.Imageform.controls['PhotoPath'].setValue(this.ImageResult1);
    }
    this.DDL_GalleryHeaderMaster_Select();
  }
    /* DDL of Gallary_Master Operation Start */
    DDL_GalleryHeaderMaster_Select(){ 
      var Moobj:any = {}; 
      Moobj.Pid='fbed3590-7a4f-4eee-9202-80ccd8e392451';
      this.mainService.OneMethod(Moobj).then(value=>{
        this.GalleryHeaderList=value;
        this.GalleryHeaderList=this.GalleryHeaderList.table;
       });
    }   
     /* DDL of Gallary_Master Operation End*/

     /* CRUD Operation Start */
    GalleryMaster_Insert()
    {
      var Moobj:any = {}; 
       Moobj.Pid ='4cd1d006-98aa-4ab0-85aa-4cd442895323';
       Moobj.FkGalleryHeaderMaster=this.Imageform.value.FkGallaryheader;
       Moobj.GalleryImagesTitle=this.Imageform.value.Name;
       Moobj.GalleryPhotoPath=this.Imageform.value.PhotoPath;
       this.mainService.OneMethod(Moobj).then(value=>{
       this.SpResult=value;
       this.ImageList= this.SpResult.table;
       console.log(  this.ImageList)
       alert(JSON.stringify( this.SpResult.table[0].column2));
      });
        this.Reset()
     }
     
     GalleryMaster_Select(){ 
      var Moobj:any = {}; 
      Moobj.Pid='1904fee7-7d82-4e35-a01a-7c66ffdca36f';
      Moobj.PkGalleryMaster=0;
      this.mainService.OneMethod(Moobj).then(value=>{
        this.CategoryList=value;
        this.CategoryList=this.CategoryList.table;
       });
    }   
     
    GalleryMaster_Update(){
      var Moobj:any = {}; 
      Moobj.Pid='1c8fc967-1124-436b-b076-79409a53106d';
      Moobj.PkGalleryMaster=this.Imageform.get('PkImage').value;
      Moobj.FkGalleryHeaderMaster=this.Imageform.value.FkGallaryheader;
      Moobj.GalleryImagesTitle=this.Imageform.value.Name;
      Moobj.GalleryPhotoPath=this.Imageform.value.PhotoPath;
      this.mainService.OneMethod(Moobj).then(value=>{
        this.SpResult=value;
        alert(JSON.stringify( this.SpResult.table[0].column1));
        this.GalleryMaster_Select();
      });
      this.Reset();
    }
     
    GalleryMaster_Delete(){ 
      var Moobj:any = {}; 
      Moobj.Pid='a0347c9a-814f-4869-852b-f56479612a74';
      Moobj.PkGalleryMaster=this.Imageform.get('PkImage').value;
      this.mainService.OneMethod(Moobj).then(value=>{
      this.SpResult=value;
      alert(JSON.stringify( this.SpResult.table[0].column1));
      this.GalleryMaster_Select(); 
      });
      this.Reset()
      }

     Reset(){
      this.Imageform.reset();
     }
    
    RowSelected(u:any){
      this.Imageform.controls['PkImage'].setValue(u.pkGalleryMaster);
      this.Imageform.controls['FkGallaryheader'].setValue(u.galleryHeader);
      // this.Imageform.controls['Name'].setValue(u.galleryHeader);
      this.Imageform.controls['Name'].setValue(u.galleryImagesTitle);
      this.Imageform.controls['PhotoPath'].setValue(u.galleryPhotoPath);

      window.scroll({ 
      top: 0
     });
    }
    /* CRUD Operation End */
  

}
