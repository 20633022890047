<app-home-title></app-home-title>
<!-- Start Inner Privacy Policy Area -->
<section class="privacy-policy pt-100 pb-160">
    <div class="container">
        <div class="single-privacy">
            <h3 class="mt-0"> Privacy Policy</h3>
            <p><b>RTD Research </b>respects the privacy of it's users and is committed to protect it in all respects. With a view to offer most enriching and holistic internet experience to it's users, RTD Research offers a vast repository of Online Sites and variety of community services. The information about the user as collected by RTD Research is:<br> (a) information supplied by users and <br>(b) information automatically tracked while navigation (Information). <br>
                By using RTD Research's website or its services, you consent to collection, storage, and use of the personal information you provide (including any changes thereto as provided by you) for any of the services that we offer.
                </p>
           

            <h3>USER INFORMATION</h3>
            <p>To avail certain sites / services on our websites, users are required to provide certain information for the registration process that may include but not limited to :- a) your name, b) email address, c) sex, d) age, e) PIN code , f) credit card or debit card details g) medical records and history h) sexual orientation, i) biometric information, j) password etc., and / or your occupation, interests, and the like. The Information as supplied by the users enables us to improve our sites and provide you the most user-friendly experience.</p>

            <p>All required information is service dependent and RTD Research may use the above said user information to, maintain, protect, and improve its services (including advertising services) and for developing new services.</p>
            <p>Such information will not be considered as sensitive if it is freely available and accessible in the public domain or is furnished under the Right to Information Act, 2005, any rules made there under or any other law for the time being in force.</p>
            <p>For placing an order on RTD Research shopping the information shall be used to service user requests, and the such information shall include user’s name, mailing address, email and phone number. This information shall be gathered on the purchase of products/gift certificates, or sign up for email notifications.</p>

            <p>We also store the first 6 and last 4 digits of user card number in a secure and encrypted manner. The first 6 digits (also known as the Bank Identification Number) are used to identify the bank name and country where user card was issued. The first 6 and last 4 digits are together used for fraud detection and prevention purposes.</p>

            <p>The primary reason for gathering information is for order processing, shipping and customer service. For example, we may contact user to provide an update on order, information regarding the shipping status or to clarify questions related to the order. We also use this information to improve our products, services, website content and navigation.</p>
           

            <h3>COOKIES</h3>
            <p>To improve the responsiveness of the sites for our users, we may use "cookies", or similar electronic tools to collect information to assign each visitor a unique, random number as a user identification (User ID) to understand the user's individual interests using the identified computer. Unless you voluntarily identify yourself (through registration, for example), we will have no way of knowing who you are, even if we assign a cookie to your computer. The only personal information a cookie can contain is information you supply. A cookie cannot read data off your hard drive. Our advertisers may also assign their own cookies to your browser (if you click on their ads), a process that we do not control. We receive and store certain types of information whenever you interact with us via website, application or Service though your computer/laptop/netbook or mobile/ tablet/ pad/ handheld device etc. </p>

            
            <h3>Opting out</h3>
            <p>If a user opts out using the Ads Settings, the unique DoubleClick cookie ID on the user's browser is overwritten with the phrase "OPT_OUT". Because there is no longer a unique cookie ID, the opt-out cookie can't be associated with a particular browser. </p>

            <h3>Log File Information</h3>
            <p>We automatically collect limited information about your computer's connection to the Internet, mobile number, including your IP address, when you visit our site, application or service. Your IP address is a number that lets computers attached to the Internet know where to send you data -- such as the pages you view. We automatically receive and log information from your browser, including your IP address, your computer's name, your operating system, browser type and version, CPU speed, and connection speed. We may also collect log information from your device, including your location, IP address, your device's name, device's serial number or unique identification number (e.g. UDiD on your iOS device), your device operating system, browser type and version, CPU speed, and connection speed etc. </p>

            <h3>Information from other Sources</h3>
            <p>We may receive information about you from other sources, add it to our account information and treat it in accordance with this policy. If you provide information to the platform provider or other partner, whom we provide services, your account information and order information may be passed on to us. We may obtain updated contact information from third parties in order to correct our records and fulfil the Services or to communicate with you. </p>

            <h3>Demographic and purchase information</h3>
            <p>We may reference other sources of demographic and other information in order to provide you with more targeted communications and promotions. We use Google Analytics, among others, to track the user behaviour on our website. Google Analytics specifically has been enable to support display advertising towards helping us gain understanding of our users' Demographics and Interests. The reports are anonymous and cannot be associated with any individual personally identifiable information that you may have shared with us. If you don’t want Analytics to be used in your browser, you can install the Google Analytics browser add-on </p>

            <h3>LINK TO THIRD PARTY SITES</h3>
            <p><b>RTD Research</b> includes links to other websites. Such sites are governed by their respective privacy policies, which are beyond our control. Once you leave our servers (you can tell where you are by checking the URL in the location bar on your browser), use of any information you provide is governed by the privacy policy of the operator of the site you are visiting. That policy may differ from ours. If you can't find the privacy policy of any of these sites via a link from the site's homepage, you should contact the site directly for more information. </p>
            <p> When we present information to our advertisers -- to help them understand our audience and confirm the value of advertising on our website -- it is usually in the form of aggregated statistics on traffic to various pages within our site. When you register with RTD Research, we contact you from time to time about updation of your content to provide the users such features that we believe may benefit you. </p>

            <h3>INFORMATION SHARING</h3>
            <p>RTD Research shares the sensitive personal information to any third party without obtaining the prior consent of the User in the following limited circumstances: </p>
            <br>
            
a)	When it is requested or required by law or by any court or governmental agency or authority to disclose, for the purpose of verification of identity, or for the prevention, detection, investigation including cyber incidents, or for prosecution and punishment of offences. These disclosures are made in good faith and belief that such disclosure is reasonably necessary for enforcing these Terms; for complying with the applicable laws and regulations.<br>
b)	Use of Customer Information: RTD Research will not share, sell, rent or otherwise disclose the personal information (name, postal address, e-mail address) of the customers without their advance permission, unless otherwise ordered by a court of law. All personal information collected is used solely to contact the customers in regard to their use of this service. Information gathered from control panel usage is logged solely to determine customers' needs related to the RTD Research service and web design.<br>
c)	Use of newsletter Subscriber Information: RTD Research is not an e-mail list rental service and does not share, rent, sell or otherwise disclose any e-mail addresses or other information that the customers provide or collect regarding their e-zine subscribers. Customers retain complete ownership and control of their subscription lists at all times.

<h3>ACCESSING AND UPDATING PERSONAL INFORMATION</h3>
<p>When you use RTD Research's websites, we make good faith efforts to provide you, as and when requested by you, with access to your personal information and shall further ensure that any personal information or sensitive personal data or information found to be inaccurate or deficient shall be corrected or amended as feasible, subject to any requirement for such personal information or sensitive personal data or information to be retained by law or for legitimate business purposes. We ask individual users to identify themselves and the information requested to be accessed, corrected or removed before processing such requests, and we may decline to process requests that are unreasonably repetitive or systematic, require disproportionate technical effort, jeopardize the privacy of others, or would be extremely impractical (for instance, requests concerning information residing on backup tapes), or for which access is not otherwise required. In any case where we provide information access and correction, we perform this service free of charge, except if doing so would require a disproportionate effort. Because of the way we maintain certain services, after you delete your information, residual copies may take a period of time before they are deleted from our active servers and may remain in our backup systems.</p>

<h3>INFORMATION SECURITY</h3>
<p>We take appropriate security measures to protect against unauthorized access to or unauthorized alteration, disclosure or destruction of data. These include internal reviews of our data collection, storage and processing practices and security measures, including appropriate encryption and physical security measures to guard against unauthorized access to systems where we store personal data.</p>
<p>All information gathered on RTD Research is securely stored within the RTD Research controlled database. The database is stored on servers secured behind a firewall; access to the servers is password-protected and is strictly limited. However, as effective as our security measures are, no security system is impenetrable. We cannot guarantee the security of our database, nor can we guarantee that information you supply will not be intercepted while being transmitted to us over the Internet. And, of course, any information you include in a posting to the discussion areas is available to anyone with Internet access.</p>

<p>However, the internet is an ever evolving medium. We may change our privacy policy from time to time to incorporate necessary future changes. Of course, our use of any information we gather will always be consistent with the policy under which the information was collected, regardless of what the new policy may be.</p>

<p>We use third-party advertising companies to serve ads when you visit our Web site. These companies may use information (not including your name, address, email address or telephone number) about your visits to this and other Web sites in order to provide advertisements about goods and services of interest to you. Any complaints or concerns with regards to content or to report any abuse of laws or breach of these terms may be taken up with the designated grievance officer as mentioned below via post or by sending an email to 
    <a href="info@rtdresearch.com."></a></p>

    <h3>Grievance Redressal</h3>
    <p>Redressal Mechanism: Any complaints, abuse or concerns with regards to content and or comment or breach of these terms shall be immediately informed to the designated Grievance Officer as mentioned below via in writing or through email signed with the electronic signature to 
         <a href="info@rtdresearch.com."></a></p>
           
        </div>
    </div>
</section>
<!-- End Inner Privacy Policy Area -->
