<section>
    <div class="container">
        <div class="Wrapper">
            <div class="register-form">
         <h3 style="color: black;text-align: center;font-style: normal;">About Us</h3>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
   
     <form [formGroup]="agendaform">
   
       <div class="form-group row">
         <label class="col-sm-3 col-form-label">ID</label>
         <div class="col-sm-7">
           <input  class="form-control" formControlName="PkAgenda">
         </div>
       </div>
       <div class="form-group row">
         <label for="inputPassword" class="col-sm-3 col-form-label"> Description</label>
       <div class="col-sm-7">
             <ck-editor name="editor1" [(ngModel)]="editorValue" formControlName="Description"
             skin="moono-lisa" lang="en" [fullPage]="true"></ck-editor>
            
       </div>
       </div>
       

     
      <div class="row">
           <div class="col-sm-2 offset-sm-2"  >
                    <button type="submit" class="btn btn-primary"           
                             (click)="Insert()">Submit</button>
           </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

          <div class="col-sm-2"  >
                     <button type="submit" class="btn btn-primary" 
                             (click)="Update()">update</button>
          </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

          <div  class="col-sm-2" >
                       <button type="submit" class="btn btn-primary" 
                                  (click)="Delete()">Delete</button>
          </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

          <div  class="col-sm-2" >
                       <button type="submit" class="btn btn-primary" 
                                 (click)="Select()">List</button>
          </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

          <!-- <div  class="col-sm-2" >
                         <button type="submit" class="btn btn-primary" 
                                   (click)="Reset()">Reset</button>
           </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; -->
      </div>

     </form>
    </div>
  </div>
 </div>
</section>

<div class="container">    
    <div class="table-responsive">   
    <table class="table" id="tblList">
      <thead>
        <tr>
          <th>ID</th>
          <th> Description</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let cmp of CategoryList;">
          <td (click)="RowSelected(cmp)">{{cmp.pkAboutUs}}</td>
          <td [innerHTML]="cmp.discription">{{cmp.discription}}</td>
        </tr> 
      </tbody>
    </table>
  </div>
  </div>

