import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { VideoGallaryComponent } from 'src/app/site/video-gallary/video-gallary.component';
import { AuthGuard } from './services/auth-guard.service';
import { AboutUsComponent } from './site/about-us/about-us.component';

import { CenterComponent } from './site/center/center.component';
import { InvoiceComponent } from './site/Components/invoice/invoice.component';
//import { BlogEditorComponent } from './site/Components/blog-editor/blog-editor.component';
import { ContactComponent } from './site/contact/contact.component';
import { DisclaimerComponent } from './site/disclaimer/disclaimer.component';
import { EventsComponent } from './site/events/events.component';
import { GallaryComponent } from './site/gallary/gallary.component';
import { GovermentComponent } from './site/goverment/goverment.component';
import { HomeComponent } from './site/home/home.component';

import { LayoutComponent } from './site/layout/layout.component';
import { LoginComponent } from './site/login/login.component';
import { MemberFormComponent } from './site/member-form/member-form.component';
import { PrivacyPolicyComponent } from './site/privacy-policy/privacy-policy.component';
import { TermsConditionComponent } from './site/terms-condition/terms-condition.component';

const routes: Routes = [
  {path: '', 
  component: LayoutComponent,
  children: [
    { path: '', component: HomeComponent},
    { path: 'home', component: HomeComponent},
    { path: 'about', component: AboutUsComponent},
    { path: 'privacypolicy', component: PrivacyPolicyComponent},
    { path: 'termscondition', component: TermsConditionComponent},
    { path: 'disclaimer', component: DisclaimerComponent},
    { path: 'contact', component: ContactComponent},
    { path: 'login', component: LoginComponent},
    { path: 'goverment', component:  GovermentComponent},
    { path: 'gallary', component:  GallaryComponent},
    { path: 'center', component:  CenterComponent},
    { path: 'event', component:  EventsComponent},
    { path: 'bemember', component: MemberFormComponent},
    { path: 'video', component: VideoGallaryComponent},
    { path: 'Invoice', component: InvoiceComponent},
    
    
    //{ path: 'addpost', component: BlogEditorComponent, canActivate: [AuthGuard] },


  ]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{onSameUrlNavigation: 'reload',scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
