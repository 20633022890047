import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { MainService } from 'src/app/services/main.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-renewal-list',
  templateUrl: './renewal-list.component.html',
  styleUrls: ['./renewal-list.component.css']
})
export class RenewalListComponent implements OnInit {
  renewalform: FormGroup;
  SpResult: any;
  memberList: any;
  loading1=false;
  fileName = 'ExcelSheet.xlsx';
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };

  constructor(private mainService: MainService, private fb: FormBuilder, private calendar: NgbCalendar) { }

  ngOnInit(): void {
    this.renewalform=this.fb.group({
      ChequeDate: new FormControl(new Date()),
    });
  }
  getMember()
  {
    this.loading1 = true;
    var MoObj: any = {}
    MoObj.Pid = "7634121c-382d-462c-8b3e-7647682a64b3";
    MoObj.PYear = this.renewalform.value.ChequeDate;
    //alert(JSON.stringify(MoObj.MemberDate))
    this.mainService.TwoMethod(MoObj).then(value => {
      this.SpResult = value;
      this.loading1 = false;
      this.SpResult = this.SpResult.table
       console.log(JSON.stringify(this.SpResult))
      // alert(JSON.stringify(this.SpResult));
      
    });
  }

  // list to convert into excel
  exportexcel(): void {
    /* pass here the table id */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }

}
