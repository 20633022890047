<app-home-title></app-home-title>
<section class="privacy-policy pt-100 pb-160">
    <div class="container">
        <div class="single-privacy">
            <h3 class="mt-0">Terms & Conditions</h3>

            <h6>Welcome to RTDResearch.com (the "Site").</h6><br>
<h3>1. ACCEPTANCE OF TERMS OF USE</h3><br>
 Please read the following terms and conditions as these terms of use ("Terms")
constitute a legally binding agreement between you and the “Partnership Firm”
regarding your use of the Site and/or the services provided by the “Partnership Firm”
which include but not limited to aggregation of important news, newsletters, analysis
focusing on RTDResearch industry or availability of various types of content through
the Site or any mobile or internet connected device or otherwise (the Services).<br><br>
You also represent that you are an individual and not a corporation.<br><br>
The “Partnership Firm” reserves the right, at its discretion, to change, modify, add, or
remove portions of these Terms at any time by posting the amended Terms. Please
check these Terms periodically for changes. Your continued use of the site or Services
after the posting of changes constitutes your binding acceptance of such changes. In
addition, when using any particular services, you may be subject to any posted
guidelines, rules, product requirements or sometimes additional terms applicable to
such services. All such guidelines, rules, product requirements or sometimes additional
terms are hereby incorporated by reference into the Terms.<br><br>
YOUR ACCESS OR USE OF THE SITE OR SERVICE SHALL MEAN THAT YOU HAVE READ,
UNDERSTAND AND AGREE TO BE BOUND BY THE TERMS. By accessing or using any
Website or Services you also represent that you have the legal authority as per
applicable law (including but not limited to age requirement) to accept the Terms on
behalf of yourself and/or any other person you represent in connection with your use of
the Site or Services. If you do not agree to the Terms, you are not authorized to use the
Site or Services.<br><br>

<h3>2. THE SERVICE REGISTRATION AND ACCESS TO USE</h3><br>

<b>o Registration:</b> To register for the Services, you may be required to open an account by
completing the registration process (i.e. by providing us with current, complete and
accurate information as prompted by the applicable registration form). You will also
choose a password and a user name. You are entirely responsible for maintaining the

confidentiality of your password and account. In particular, as a parent or legal
guardian, you acknowledge and assume sole responsibility to ensure that content which
is meant for mature audiences (i.e, above the age of majority) is not accessed by
children. Hence, you may not share your log in credentials with your children. You
expressly agree to absolve the “Partnership Firm” of any responsibility / liability in this
regard.<br>
<b>o Facebook Connect:</b> You may also register for the Services by using your Facebook
username and password. If, however, you are under 18 years of age, you may log in to
the Services using Facebook Connect and utilize the Services only under the supervision
of your parent or legal guardian. Using Facebook Connect allows us to personalize and
enhance your experience while using the Services, based on your personal information,
profile, likes, and other relevant information. When you use this feature, you expressly
consent to information about your activity on the Services (i.e. what you have read,
what you have liked, ratings given by you, etc.) being continuously released and
automatically posted on your Facebook account (which has been used to log in) and
made available to your friends on Facebook. You may control the information being
shared through Facebook Connect by changing your account / privacy settings. By
registering through Facebook, you agree to the Terms stated herein and in addition to
any other specific terms which shall be posted at an appropriate location of the Site.
Each registration is for a single individual user only.<br>

<b>o Subscription:</b> Your subscription to the Services in a particular geographical territory
shall be valid for that territory only and shall not automatically entitle you to access
your account from a different geographical territory, unless specifically permitted by
the “Partnership Firm”.<br>
<b>o Geographic Limitation:</b> The Site and/or the Services are controlled and offered by
“Partnership Firm” from its facilities in the territory of India. “Partnership Firm” makes
no representations that the Site or Services are appropriate or available for use in other
locations. If you are accessing or using the Site or Services from other jurisdictions, you
do so at your own risk and you are responsible for compliance with local law.
Notwithstanding the foregoing, the Site or Services may contain or provide links to
content hosted on website located outside of the India.<br>
<b>o Access to use:</b> To access the Services, you will be asked to enter your individual user
name and password, as chosen by you during your registration. Therefore, the
“Partnership Firm” does not permit any of the following:-<br>
▪ Any other person sharing your account and Password;<br>
▪ Any part of the Site being cached in proxy servers and accessed by individuals who have
not registered with the “Partnership Firm” as users of the Site; or<br>

▪ Access through a single account and Password being made available to multiple users
on a network.<br>
  If the “Partnership Firm” reasonably believes that an account and password is being
used / misused in any manner, the “Partnership Firm” shall reserve the right to cancel
access rights immediately without notice, and block access to all users from that IP
address. “Partnership Firm” reserves the right to reject any user name selected by you
and/or revoke your right to any previously selected user name and give such user name
to any other person or entity in Partnership Firm's sole discretion and without any
liability to you. Furthermore, you shall be entirely responsible for any and all activities
that occur under your account. You agree to notify the “Partnership Firm” immediately
of any unauthorized use of your account or any other breach of security. The
“Partnership Firm” will not be liable for any loss that you may incur as a result of
someone else using your password or account, however, you could be held liable for
losses incurred by the “Partnership Firm” or another party due to someone else using
your account or password. If messages sent to an email address provided by you and
associated with your account are returned as undeliverable or wrong address;
“Partnership Firm” reserves the right to terminate your account immediately with or
without notice to you and without any liability to you or any third party<br>
<b>o Availability:</b> The availability of content through the Services may change from time to
time. You are responsible for all Internet access charges. Please check with your
Internet provider for information on possible Internet data usage charges.<br>
<br><br>
<h3>3. BILLING (If Applicable)</h3><br>
By signing up for the Services, you are expressly agreeing that the “Partnership Firm” is
authorized to charge you subscription fee as per your selected plan, any other fees for
additional services you may purchase, and any applicable taxes in connection with your
use of the Services through the credit card or other payment method accepted by the
“Partnership Firm” ("Payment Method") that you provided during registration. If you
want to use a different Payment Method than the one you signed up for during
registration, or if there is a change in your credit card validity or expiration date, you
may edit your Payment Method information by clicking on your account. If your
Payment Method expires and you do not edit your Payment Method information or
cancel your account, you authorize us to continue billing, and you will remain
responsible for any uncollected amounts.<br>
As used in these Terms, "billing" shall indicate either a charge or debit, as applicable,
against your Payment Method. The subscription fee will be billed at the beginning of
your subscription and on each subsequent renewal (as per plan chosen by you)
thereafter unless and until you cancel your subscription or the account or service is
otherwise suspended or discontinued pursuant to these Terms. To see the
commencement date for your next renewal period, go to the billing information section
on your account page.<br><br>
In order to sustain the Services, it is important that you honor the payment obligations
to which you have agreed. Accordingly, the “Partnership Firm” reserves the right to
pursue any amounts you fail to pay in connection with the Services. You will remain
liable to the “Partnership Firm” for all such amounts and all costs incurred by the
“Partnership Firm” in connection with the collection of these amounts, including,
without limitation, collection agency fees, reasonable attorneys' fees, and arbitration or
court costs.<br>
You also understand and acknowledge that the “Partnership Firm” only facilitates the
third party payment gateway for processing of payment. This facility is managed by the
third party payment gateway provider and you are required to follow all the terms and
conditions of such third party payment gateway provider. You are responsible for the
accuracy and authenticity of the information provided by you, including the bank
account number/credit card details and the like. You agree and acknowledge that the
“Partnership Firm” shall not be liable and in no way be held responsible for any losses
whatsoever, whether direct, indirect, incidental or consequential, including without
limitation any losses due to delay in processing of payment instruction or any credit
card fraud.<br>
You can file any complaint related to payment transfer at Site and the same shall be
forwarded to the concerned third party payment gateway provider for redressal.<br>
<b>Free Trials:</b> You may be offered a free trial period at the start of your membership of
the Services, solely at the discretion of the “Partnership Firm”. The duration and the
terms of the free trial period shall be at the discretion of the “Partnership Firm” and
shall be specified during sign up. Free trials may not be combined with any other
offer(s). Free trials will not be given in cases of renewal of membership or activation of
another account from a physical address and/or email address and/or device which is
linked to an already existing account, etc. Billing may automatically commence at the
end the free trial period, without prior notice, as per the subscription plan chosen by
you, unless you cancel your membership prior to the expiry of the free trial period. It is
hence recommended that you keep track of your account, including the date when the
free trial period ends.<br><br>

Once billing commences, we will continue to bill your Payment Method for your
membership fee until you cancel. You may cancel your membership at anytime;
however, there are no refunds or credits for partially used periods.<br><br>
<h3>4. LINKS TO OTHER SITES</h3><br>
The Site or the Services may contain the links or pointers to other websites but you
should not infer or assume that the “Partnership Firm” operates, controls, or is
otherwise connected with these other websites. When you click on a link within the Site,
the “Partnership Firm” may not warn you that you have left the Site and are subject to
the terms and conditions (including privacy policies) of another website. Please be
careful to read the terms of use and privacy policy of any other website before you
provide any confidential information or engage in any transactions. You should not rely
on these Terms to govern your use of another website.<br>
The “Partnership Firm” is not responsible for the content or practices of any other
website even if it links to the Site and even if the website is operated by a “Partnership
Firm” affiliated or otherwise connected with the “Partnership Firm”. You acknowledge
and agree that the “Partnership Firm” is not responsible or liable to you for any content
or other materials hosted and served from any website other than the Site.<br><br>

<h3>5. CONTENT OWNERSHIP AND LIMITED LICENSE</h3><br>
<b>o "Partnership Firm Content"</b> means “Partnership Firm” proprietary content, including
but not limited to, “Partnership Firm” trademarks and logos made available through the
Site and Services, excluding Third Party Content and User Submissions.<br>
<b>o "Third Party Content"</b> You may be able to access, review, display or use third party
services, resources, content or information via the Site or the Services.<br>
<b>o "User Submissions"</b> means the text, data, graphics, images, photos, video or
audiovisual content, hypertext links and any other content that the “Partnership Firm”
allows its users to uploads, posts, flips, compiles or otherwise provided to “Partnership
Firm” via the Site and Services, as applicable.<br>
<b>o Ownership:</b> The Site, Services and the “Partnership Firm” Content are protected by
copyright, trademark and other applicable laws. Except as expressly provided in these
Terms, “Partnership Firm” and its licensors exclusively own all right, title and interest in
and to the Site, Services, and the “Partnership Firm” Content, including all associated
intellectual property rights. You may not remove, alter or obscure any copyright,
trademark, service mark or other proprietary rights notices incorporated in or
accompanying the Site, Services or “Partnership Firm” Content. “Partnership Firm”
claims no ownership interest in any Third Party Content and expressly disclaims any
liability concerning those materials.<br>

<b>o Limited License:</b> Subject to your compliance with the Terms herein, the “Partnership
Firm” hereby grants you a personal, limited, non-exclusive, non-transferable, freely
revocable license to use the Services for the personal and non-commercial use only.
Except for the foregoing limited license, no right, title or interest shall be transferred to
you. Content on the Site and/or the Services is provided to you AS IS for your
information and personal use only and may not be used, copied, reproduced,
distributed, transmitted, broadcast, displayed, sold, licensed, or otherwise exploited for
any other purposes whatsoever without the prior written consent of the respective
owners. We reserve all rights not expressly granted in and to the Site and/or the
Services and the Content. These Terms do not authorize you to, and you may not,
reproduce, distribute, publicly display, publicly perform, communicate to the public,
make available, create derivative works of or otherwise use or exploit any Third Party
Content or User Submissions in violation of applicable copyright law. Any unauthorized
use of the Contents or the Services will result in termination of the limited license
granted by the “Partnership Firm” and cancellation of your membership. Use of Site or
the Services for any unauthorised purpose may result in severe civil and criminal
penalties. The “Partnership Firm” does not promote, foster or condone the copying of
Content, or any other infringing activity and the owners of Third Party Content or User
Submissions may have the right to seek damages against you for any such violation.
<br>
<b>o Interactions between Users:</b> You are solely responsible for your interactions
(including any disputes) with other users. You understand that “Partnership Firm” does
not in any way screen “Partnership Firm” users. You are solely responsible for, and will
exercise caution, discretion, common sense and judgment in, using the Site and Services
and disclosing personal information to other “Partnership Firm” users. You agree to
take reasonable precautions in all interactions with other “Partnership Firm” users,
particularly if you decide to communicate with “Partnership Firm” user offline or meet
them in person. Your use of the Site, Services, “Partnership Firm” Content, and any other
content made available through the Site or Services is at your sole risk and discretion,
and “Partnership Firm” hereby disclaims any and all liability to you or any third party
relating thereto. “Partnership Firm” reserves the right to contact “Partnership Firm”
users, in compliance with applicable law, in order to evaluate compliance with the rules
and policies in this Terms You will cooperate fully with “Partnership Firm” to
investigate any suspected unlawful, fraudulent or improper activity via the Services.
By making available any User Submissions through the Site and Services, you hereby
grant to “Partnership Firm” and its users a worldwide, non-exclusive, perpetual,
irrevocable, transferable, assignable, royalty-free license, with the right to sublicense, to
use, copy, adapt, modify, distribute, publicly display, publicly perform, transmit, stream,
broadcast, make available, communicate to the public and otherwise use and exploit

such User Submissions through or by means of the Site and the Services and/or to
incorporate it in other works in any form, media, or technology now known or later
developed throughout the world. “Partnership Firm” does not claim any ownership
rights in any such User Submissions and nothing in these Terms will be deemed to
restrict any rights that you may have to use any such User Submissions. You hereby
acknowledge and agree that “Partnership Firm” shall not be liable for any Uses of your
User Submissions by any third party that had access to your User Submissions during
the period in which your User Submissions was available on or through the Services.
You acknowledge and agree that “Partnership Firm” reserves the right to not to publish,
display the User Submissions or modify, amend or delete any User Submissions on the
receipt of any complaint, that the User Submissions are infringing or in violation of any
applicable laws. You acknowledge and agree that you are solely responsible for all User
Submissions that you make available through the Site or Services. Accordingly, you
represent and warrant that: (a) you either are the sole and exclusive owner of all User
Submissions that you make available through the Site or Services or you have all rights,
licenses, consents and releases that are necessary to grant to “Partnership Firm” the
rights in such User Submissions, as contemplated under these Terms; (b) neither the
User Submissions nor your accessing, posting, submission or transmittal of the User
Submissions or Partnership Firm's use of the User Submissions (or any portion thereof)
on, through or by means of the Site and the Services or any other permitted use will
infringe, misappropriate or violate a third party's patent, copyright, trademark, trade
secret, moral rights or other intellectual property rights, or rights of publicity or
privacy, or result in the violation of any applicable law or regulation; and (c) no
payments of any kind shall be due to any third party, whether a copyright owner or an
agent thereof, for any use made of the User Submissions (or any portion thereof) on,
through or by means of the Site and the Services.<br>
By using the Site or the Services, you acknowledge the sole responsibility for and
assume all risk arising from your access to, use of or reliance upon any such Third Party
Content, or User Submissions and “Partnership Firm” disclaims any liability that you
may incur arising from your access to, use of or reliance upon such Third Party Content
or User Submissions. You acknowledge and agree that “Partnership Firm”: (a) is not
responsible for the availability or accuracy of such Third Party Content or User
Submissions; (b) has no liability to you or any third party for any harm, injuries or
losses suffered as a result of your access to, reliance on or use of such Third Party
Content or User Submissions; (c) does not undertake or assume any duty to monitor for
inappropriate or unlawful content on third party websites or User Submissions; and (d)

does not make any promises to remove Third Party Content from being accessed
through the Site or the Services.<br><br>
<h3>6. ADVERTISING MATERIAL</h3><br>
Part of the Site or the Services may contain advertising information or promotion
material or other material submitted to the “Partnership Firm” by third parties.
Responsibility for ensuring that material submitted for inclusion on the Site complies
with applicable international and national law is exclusively on the party providing the
information/material. Your correspondence or business dealings with, or participation
in promotions of advertisers including payment and delivery of related goods or
services, and any other terms, conditions, warranties or representations associated with
such dealings, are solely between you and such advertiser. Before relying on any
advertising material, you should independently verify its relevance for your purpose,
and should obtain appropriate professional advice. The “Partnership Firm” shall not be
responsible nor liable for any loss or claim that you may have against an advertiser or
any consequential damages arising on account of your relying on the contents of the
advertisement.<br><br>
<h3>7. COLLECTION AND USE OF PERSONAL INFORMATION</h3><br>
For information about the Partnership Firm's policies and practices regarding the
collection and use of your personally identifiable information, please read the Privacy
Policy as available on the Site. The Privacy Policy is incorporated by reference and made
part of these Terms. Thus, by agreeing to these Terms, you agree that your presence on
the Site and use of the Services are governed by the Partnership Firm's Privacy Policy in
effect at the time of your use. The “Partnership Firm” reserves the right to disclose any
information that is required to be shared, disclosed or made available to any
governmental, administrative, regulatory or judicial authority under any law or
regulation applicable to the “Partnership Firm”. The “Partnership Firm” can further
disclose your name, street address, city, state, zip code, country, phone number, email,
as it in its sole discretion believes necessary or appropriate in connection with an
investigation of fraud, intellectual property infringement, piracy, or other unlawful
activity.<br><br>
<h3>8. YOUR OBLIGATIONS</h3><br>
You hereby agree and assure the “Partnership Firm” that the Site and/or the Services
shall be used for lawful purposes only and that you will not violate laws, regulations,
ordinances or other such requirements of any applicable Central, State or local
government or any other international laws. You further concur that you shall not:<br><br>
o circumvent, remove, alter, deactivate, degrade or thwart any of the content protections
in the Site or the Services.<br><br>

o either directly or through the use of any device, software, internet site, web-based
service, or other means copy, download, stream capture, reproduce, duplicate, archive,
distribute, upload, publish, modify, translate, broadcast, perform, display, sell, transmit
or retransmit the Content unless expressly permitted by the “Partnership Firm”;<br><br>
o either directly or through the use of any device, software, internet site, web-based
service, or other means remove, alter, bypass, avoid, interfere with, or circumvent any
copyright, trademark, or other proprietary notices marked on the Content or any digital
rights management mechanism, device, or other content protection or access control
measure associated with the Content including geo-filtering mechanisms<br><br>
o use the Site or the Services in any manner that could damage, disable, overburden, or
impair and not to undertake any action which is harmful or potentially harmful to any
the Partnership Firm's server, or the network(s), computer systems / resource
connected to any the “Partnership Firm” server, or interfere with any other party's use
and enjoyment of the Site or the Services;<br><br>
o obtain or attempt to obtain any materials or information through any means not
intentionally made available through the Site/Services;<br><br>
o perform any activity which is likely to cause such harm;<br><br>
o carry out any "denial of service" (DoS, DDoS) or any other harmful attacks on
application or internet service or;<br><br>
o use the Site or the Services for illegal or unlawful purposes;<br><br>
o disrupt, place unreasonable burdens or excessive loads on, interfere with or attempt to
make or attempt any unauthorized access to any the “Partnership Firm” website or the
website of any the Partnership Firm's customer;<br><br>
o forge headers or otherwise manipulate identifiers in order to disguise the origin of any
content transmitted through the Site or the Services;<br><br>
o attempt to gain unauthorized access to the Services, other accounts and computer
systems through hacking, password mining or any other means. You shall not obtain or
attempt to obtain any materials or information through any means not intentionally
made available through the Site or the Services;<br><br>
o incorporate the Site or Services into or retransmit via, any hardware or software
application or make it available via frames or in-line links unless expressly permitted by
the “Partnership Firm” in writing;<br><br>
o create, recreate, distribute or advertise an index of any significant portion of the Site or
Services unless authorized by the “Partnership Firm”;<br><br>

o use or launch any "robots", "spiders", "offline readers" etc. or any other automated
system, that accesses the Site or the Services in a manner that sends numerous
automated requests to the Site's servers in a given period of time, which a human
cannot reasonably send in the same period by using conventional web browsing
application or tool(s) for similar purposes;<br><br>
o Send or post any unsolicited or unauthorized advertising, promotional materials, email,
junk mail, spam, chain letters or other form of solicitation like solicit login information
or access an account belonging to someone else;<br><br>
o Use the Site, Services or “Partnership Firm” Content for any commercial purpose or the
benefit of any third party or in any manner not permitted by these Terms of Use;<br><br>
o Impersonate or misrepresent your affiliation with any person or entity;<br><br>
o Encourage or enable any other individual to do any of the foregoing.<br><br>
In addition, you are strictly prohibited from creating derivative works or materials that
otherwise are derived from or based ,on the Content in any way, including montages,
mash-ups and similar videos, wallpaper, desktop themes, greeting cards, and
merchandise, unless it is expressly permitted by the “Partnership Firm” in writing. This
prohibition applies even if you intend to give away the derivative materials free of
charge.<br><br>
The Site may permit you to post user submissions including but not limited to reviews
of Content available through the Services, comments on such Content etc. You
understand that these User Submissions, once posted by you, are visible to all members
since it is a public forum. You agree not to host, display, upload, modify, publish,
transmit, update or share any information or User Submissions which<br><br>
o belongs to another person and to which the User does not have any right to;<br><br>
o is grossly harmful, harassing, blasphemous defamatory, obscene, pornographic,
paedophilic, libellous, invasive of another's privacy, hateful, or racially, ethnically
objectionable, disparaging, relating or encouraging money laundering or gambling, or
otherwise unlawful in any manner whatever;<br><br>
o harm minors in any way;<br><br>
o infringes any patent, trademark, copyright or other proprietary rights;<br><br>
o violates any law for the time being in force;<br><br>

o deceives or misleads the addressee about the origin of such messages or communicates
any information which is grossly offensive or menacing in nature;<br><br>
o impersonate another person;<br><br>
o contains software viruses or any other computer code, files or programs designed to
interrupt, destroy or limit the functionality of any computer resource;<br><br>
o threatens the unity, integrity, defence, security or sovereignty of India, friendly relations
with foreign states, or public order or causes incitement to the commission of any
cognisable offence or prevents investigation of any offence or is insulting any other
nation;<br><br>
o contain misleading information regarding the origin of the Content; or<br><br>
o otherwise contains objectionable content.<br><br>

You understand and agree that the “Partnership Firm” may, but is not obligated to,
review the User Submissions and may delete or remove it (without notice) in its sole
and absolute discretion, for any reason or without assigning any reason. More
specifically, when you review / rate any Content available on the Services (as per
functionality made available on the Site), you give the “Partnership Firm” express rights
and consent to display your rating / review in relation to the relevant Content on the
Site, including making it available to other members for viewing. If you do not want
your User Submissions / reviews / ratings to be shared in a public forum, do not use
these features. These features may change without notice to you and the degrees of
associated information sharing and functionality may also change without notice. The
“Partnership Firm” is free to use any comments, information, ideas, concepts, reviews,
or techniques or any other material contained in any communication you may send to
us ("User Feedback"), including responses to questionnaires or through postings to the
Services / the Site and User Submissions, without further compensation,
acknowledgement or payment to you for any purpose whatsoever including, but not
limited to, developing, manufacturing and marketing products and creating, modifying
or improving the Services. By posting / submitting any User Feedback / User
<br>
Submission on the Site, you grant the “Partnership Firm” a perpetual, worldwide, non-
exclusive, royalty-free irrevocable, sub-licensable license and right in such User
<br>
Feedback / User Submission to the “Partnership Firm”, including the right to display,
use, reproduce or modify the User Feedback / User Submission in any media, software
or technology of any kind now existing or developed in the future. Operators of public
search engines have the permission to use functionalities like spiders to copy materials
from the Site for the sole purpose of creating publicly available searchable indices of the

materials, but not caches or archives of such materials. We reserve the right to revoke
these exceptions either generally or in specific cases, in our sole discretion. You agree
not to collect or harvest any personally identifiable information, including account
names, from the Site, nor to use the communication systems provided by the Site for any
commercial solicitation purposes. You agree not to solicit, for commercial purposes, any
users of the Site with respect to its User Submissions. You shall be financially
responsible for your use of the Services (as well as for use of your account by others,
including without limitation minors living with you). You undertake to supervise and be
responsible for all usage of minors and access of the Site under your name or account
and absolve the “Partnership Firm” from any liability on this account. You also warrant
that all information supplied by you or members of your family for using the Services
and accessing the Site, including without limitation your name, email address, street
address, telephone number, mobile number, credit card number is correct and accurate.
Failure to provide accurate information may subject you to civil and criminal penalties.
You shall be responsible for obtaining and maintaining any equipment or ancillary
services needed to connect to, access the Site or otherwise use the Services, including,
without limitation, modems, hardware, software, and long distance or local telephone
service. You shall be responsible for ensuring that such equipment or ancillary services
are compatible with the Services. You agree that the “Partnership Firm” may directly or
through third party service providers send information to you about the various
services offered by the “Partnership Firm” from time to time. You agree that
“Partnership Firm” will have the right to investigate and take all appropriate legal
action to prevent, stop or deter violations of any of the above, including infringement of
intellectual property rights and Site and Services security issues. “Partnership Firm”
may involve and cooperate with law enforcement authorities in prosecuting users who
violate these Terms of Use or the rights of any third party. You acknowledge that
“Partnership Firm” has no obligation to monitor your access to or use of the Site,
Services or “Partnership Firm” Content or to review or edit any User Submissions or
Third Party Materials, but has the right to do so for the purpose of operating the Site
and Services, to ensure your compliance with these Terms of Use, or to comply with
applicable law or the order or requirement of a court, administrative agency or other
governmental body. “Partnership Firm” reserves the right, at any time and without
prior notice, to remove or disable access to any “Partnership Firm” Content, Third Party
Materials, and any User Submissions, that “Partnership Firm”, in its sole discretion,
considers to be in violation of these Terms or otherwise harmful to the Site or Services.<br><br>

<h3>9. PROHIBITED ACTIVITIES</h3><br>
You agree not to host, display, upload, modify, publish, transmit, update or share any
information or User Submissions which<br>

o belongs to another person and to which the User does not have any right to;<br><br>

o is grossly harmful, harassing, blasphemous defamatory, obscene, pornographic,<br><br>
paedophilic, libellous, invasive of another's privacy, hateful, or racially, ethnically
objectionable, disparaging, relating or encouraging money laundering or gambling, or
otherwise unlawful in any manner whatever;

o harm minors in any way;<br><br>

o infringes any patent, trademark, copyright or other proprietary rights;<br><br>

o violates any law for the time being in force;<br><br>

o deceives or misleads the addressee about the origin of such messages or communicates
any information which is grossly offensive or menacing in nature;<br><br>

o impersonate another person;<br><br>

o contains software viruses or any other computer code, files or programs designed to
interrupt, destroy or limit the functionality of any computer resource;<br><br>

o threatens the unity, integrity, defence, security or sovereignty of India, friendly relations
with foreign states, or public order or causes incitement to the commission of any
cognisable offence or prevents investigation of any offence or is insulting any other
nation;<br><br>

o contain misleading information regarding the origin of the Content; or<br><br>

o otherwise contains objectionable content.<br><br>

You understand and agree that the “Partnership Firm” may, but is not obligated to,
review the User Submissions and may delete or remove it (without notice) in its sole
and absolute discretion, for any reason or without assigning any reason.<br><br>
<h3>10. TERMINATION OF ACCOUNT, SUSPENSION OR DISCONTINUATION OF THE
SERVICE</h3><br>
The “Partnership Firm” reserves the right to change, suspend, or discontinue
temporarily or permanently, some or all of the Services (including the Content and the
devices through which the Services are accessed), with respect to any or all users, at any
time without notice. You acknowledge that the “Partnership Firm” may do so in its sole
discretion. You also agree that the “Partnership Firm” will not be liable to you for any
modification, suspension, or discontinuance of the Services, although if you are a paid
subscriber and the “Partnership Firm” suspends or discontinues the Services, the
“Partnership Firm” may, in its sole discretion, provide you with a credit, refund,
discount or other form of consideration (for example, the “Partnership Firm” may credit
additional days of service to your account). However, if the “Partnership Firm”
terminates your account or suspends or discontinues your access to the Services due to
your violation of these Terms, then you will not be eligible for any such credit, refund,
discount or other consideration.<br><br>
<h3>11. DISCLAIMER OF WARRANTIES AND LIABILITY</h3><br>
You understand and agree that the “Partnership Firm” provides the Services on 'as-is'
'with all faults' and 'as available' basis. You agree that use of the Site or the Services is at
your risk. All warranties including without limitation, the implied warranties of
merchantability, fitness for a particular purpose, for the title and non-infringement are
disclaimed and excluded. No representations, warranties or guarantees whatsoever are
made by the “Partnership Firm” whether express or implied and expressly disclaim any
and all representations and warranties as to the (a) accuracy, adequacy, reliability,
completeness, suitability or applicability of the information, the content, data, products
and/or services, merchantability or any warranty for fitness for a particular purpose;
(b) that the service will be uninterrupted, timely, secure, or error-free or that all
deficiencies, errors, defects or non-conformities will be corrected; (c) the quality of any
services, content, information, data, or other material on the website will meet your
expectations or requirements; (d) any errors in the site will be corrected; (e) warranties

against infringement of any third party intellectual property or proprietary rights; or (f)
other warranties relating to performance, non-performance, or other acts or omissions
of the “Partnership Firm”, its officers, directors, employees, affiliates, agents, licensors,
or suppliers etc. The “Partnership Firm” does not warrant that any of the software used
and or licensed in connection with the Services will be compatible with other third
party software or devices nor does it warrant that operation of the Services and the
associated software will not damage or disrupt other software or hardware. The
“Partnership Firm”, its affiliates, successors, and assigns, and each of their respective
investors, directors, officers, employees, agents, and suppliers (including distributors
and content licensors) shall not be liable, at any time for any, direct, indirect, punitive,
incidental, special, consequential, damages arising out of or in any way connected with
the use of Site or the Services, whether based in contract, tort, strict liability, or other
theory, even if the “Partnership Firm” have been advised of the possibility of damages.
In the event any exclusion contained herein be held to be invalid for any reason and the
“Partnership Firm” or any of its affiliate entities, officers, directors or employees
become liable for loss or damage, then, any such liability of the “Partnership Firm” or
any of its affiliate entities, officers, directors or employees shall be limited to not
exceeding subscription charges paid by you in the month preceding the date of your
claim for the particular subscription in question chosen by you.<br><br>
<h3>12. INDEMNIFICATION</h3><br>
You agree to indemnify, defend and hold harmless, the “Partnership Firm”, its affiliates,
successors, and assigns, and each of their respective investors, directors, officers,
employees, agents, and suppliers (including distributors and content licensors) from
and against any losses, claims, damages, liabilities, including legal fees and expenses,
arising out of:<br><br>
o any claim due to or arising out of your violation of these Terms, including but not
limited to a claim arising out of a breach of your representations or warranties made
hereunder;<br>
o your use or misuse of or access to the Site or the Services;<br>
o your violation of any law, regulation or third party right, including without limitation
any copyright, property, or privacy right; or<br>
o any claim that you have caused damage to a third party.<br><br>

The “Partnership Firm” reserves the right, at its own expense, to employ separate
counsel and assume the exclusive defence and control of any matter otherwise subject
to indemnification by you, and you agree to cooperate with the Partnership Firm's
defence of these claims.<br><br>

<h3>13. GRIEVANCE REDRESSAL MACHENISM</h3><br>
Any complaints or concerns with regards to content or to report any abuse of laws or
breach of these terms may be taken up with the designated grievance redressal officer
as mentioned below via post or by sending an email to <a href="#"> info@rtdresearch.com</a><br><br>
<b>Notice of Copyright Infringement:</b> Our policy is to comply with all Intellectual
Property Laws and to act expeditiously upon receiving any notice of claimed
infringement. If you believe that any work has been reproduced on this website in a
manner that constitutes copyright infringement, please provide a notice of copyright
infringement containing all of the following information:<br><br>
o A physical or electronic signature of a person authorized to act on behalf of the
copyright owner for the purposes of the complaint.<br>
o Identification of the copyrighted work claimed to have been infringed.<br>
o Identification of the material on our website that is claimed to be infringing or to be the
subject of infringing activity.<br>
o The address, telephone number or e-mail address of the complaining party.<br>
o A statement that the complaining party has a good-faith belief that use of the material in
the manner complained of is not authorized by the copyright owner, its agent or the
law.<br>
o A statement, under penalty of perjury, that the information in the notice of copyright
infringement is accurate, and that the complaining party is authorized to act on behalf of
the owner of the right that is allegedly infringed.<br><br>

<h3>14. GENERAL TERMS</h3><br>
<b>o Relationship</b>
None of the provisions of the Terms shall be deemed to constitute a partnership or
agency between you and the “Partnership Firm” and you shall have no authority to bind
the “Partnership Firm” in any manner, whatsoever. This agreement is solely for your
and the Partnership Firm's benefit and not for the benefit of any other person, except
for permitted successors and assigns under this Agreement.<br>
<b>o Assignment</b>
You may not transfer to anyone else, either temporarily or permanently, any rights to
use the Services or any part of the Services. Any attempt by you to do so is void. The
“Partnership Firm” may assign, transfer, delegate and/or grant all or any part of its
rights, privileges and properties hereunder to any person or entity.<br>

<b>o Force Majeure</b>
Neither Party shall have any liability for any interruption or delay, to access the Site due
to Force Majeure Event. For the purposes of this clause, 'Force Majeure Event' means
any event or circumstance or combination of events and circumstances which is
reasonably beyond the control of the party affected thereby and which causes or results
in default or delay in performance by such affected party of any of its obligations under
this agreement and includes an act of God, war, hostilities, civil commotion, strikes,
lockouts and other industrial disputes.<br>
<b>o Applicable Law</b><br>
These Terms are governed by and construed in accordance with, the laws of India
without giving effect to principles of conflict of law. In the event of any dispute or claim
by you against the “Partnership Firm”, you agree to submit to the exclusive jurisdiction
of courts at New Delhi.<br>
<b>o Limited Time To Bring Your Claim</b><br>
You and the “Partnership Firm” agree that any cause of action arising out of or related to
use of the Site or the Services must commence within one (1) year after the cause of
action accrues otherwise, such cause of action will be permanently barred.<br>
<b>o Survival</b><br>
Rights and obligations under the Terms which by their nature should survive will
remain in full effect after termination or expiration of the subscription.<br>
<b>o Non Waiver</b><br>
Any express waiver or failure to exercise promptly any right under this agreement will
not create a continuing waiver or any expectation of non-enforcement.<br>
<b>o Entire Agreement</b><br>
These Terms constitute the entire agreement between the parties with respect to the
subject matter hereof and supersedes and replaces all prior or contemporaneous
understandings or agreements, written or oral, regarding such subject matter.<br><br>
                            <p>Copyright Notice</p>
<b>Copyright ©2020 RTD RESEARCH. All rights reserved.</b>
        </div>
    </div>
</section>    