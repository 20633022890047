<h1 style="text-align: center;">{{heading}}</h1>


<table class="table">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">payment_id</th>
        <th scope="col">status</th>
        <th scope="col">amount</th>
        <th scope="col">buyer_name</th>
        <th scope="col">buyer_phone</th>
        <th scope="col">buyer_email</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of categoryList">
        <th scope="row">1</th>
        <td>{{data.payment_id}}</td>
        <td>{{data.status}}</td>
        <td>{{data.amount}}</td>
        <td>{{data.buyer_name}}</td>
        <td>{{data.buyer_phone}}</td>
        <td>{{data.buyer_email}}</td>
      </tr>
    </tbody>
  </table>
