import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/services/main.service';
import { PaymentService } from 'src/app/services/payment.service';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent implements OnInit {
  categoryList:any;
  heading:string;
  paymentList:any;
  mojoId:any;
  status:string;
  id:any;
  constructor(private payservice:PaymentService,private mainService:MainService) { }

  ngOnInit() {
    this.paymentInvoice();
  }

  paymentInvoice(){
    let url=localStorage.getItem('url')
    this.payservice.TwoMethod(url).then(value=>{
      this.categoryList=value;
      this.categoryList=this.categoryList.payment_request.payments[0];
      this.id=this.categoryList.payment_request.status;
      this.status=this.categoryList.payment_request.status;
      this.mojoId=this.categoryList.payment_request.payments[0].payment_id;
      if(this.categoryList.payment_request.status=='Completed'){
        /* update after payment received */
        var moobj:any={}
        moobj.Pid='fa8bb8c2-90e0-41fe-86bf-38bf0311b661';
        moobj.TransactionID=this.id;
        moobj.MojoTransID=this.mojoId;
        moobj.PStatus=this.status;
        this.mainService.TwoMethod(moobj).then(value => {
          this.paymentList = value;
          this.paymentList = this.paymentList.table;
          console.log(JSON.stringify(this.paymentList))
        })
        this.heading="payment done successfully";
      }
      else{
        this.heading="payment failed";
      }
      console.log(JSON.stringify(this.categoryList));
    })
  }
}
