
        <!-- Start Footer Area -->
        <section class="footer-section pt-100">
            <div class="container">
                <div class="subscribe-area">
                    <div class="subscribe-title">
                        <h3>Sign Up For The Newsletter</h3>
                        <p>Subscribe Our newsletter keep up with all the latest news</p>
                    </div>
                    <form class="newsletter-form">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <input type="email" class="form-control" placeholder="Enter Email" name="EMAIL" required autocomplete="off">
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <button type="submit">Subscribe</button>

                                <div id="validator-newsletter" class="form-result"></div>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="footer-area-content">
                  <!--   <img src="assets/img/LOGO.png" alt="image"> -->
                  <h3>OFROH</h3>
                       
                    <p><b>Head Office:</b><br>
                        Plot No.8,Shivalay, Near R.N Lawn, Shri Narayan Nagar<br>
                        Bypass Road, Umred, Nagpur, Pin-Code: 441-203 |<br>
                        Mobile: +91-96893-99223 | E-mail: ofroh2020@gmail.com |<br></p>
                    <ul class="footer-list">
                        <li>
                            <a href="/https://www.facebook.com/groups/761959734579690/">
                                <i class="flaticon-facebook"></i>
                            </a>
                        </li>

                        <!-- <li>
                            <a href="single-events.html">
                                <i class="flaticon-twitter"></i>
                            </a>
                        </li> -->

                        <li>
                            <a routerLink="#">
                                <i class="flaticon-linkedin"></i>
                            </a>
                        </li>

                        <!-- <li>
                            <a href="single-events.html">
                                <i class="flaticon-envelope"></i>
                            </a>
                        </li> -->

                        <!-- <li>
                            <a routerLink="/https://www.instagram.com/rtd_research/ ">
                                <i class="flaticon-instagram"></i>
                            </a>
                        </li> -->

                        <!-- <li>
                            <a href="single-events.html">
                                <i class="flaticon-pinterest"></i>
                            </a>
                        </li> -->
                    </ul>
                </div>
            </div>

            <div class="copy-right-area">
                <div class="container">
                    <ul class="copy-right-list">
                        <!-- <li>
                            <a href="single-events.html">Home</a>
                        </li>

                        <li>
                            <a href="about.html">About</a>
                        </li>

                        <li>
                            <a href="speakers-1.html">Sitemap</a>
                        </li>

                        <li>
                            <a href="schedule-1.html">Blog</a>
                        </li>

                        <li>
                            <a href="faq.html">FAQ</a>
                        </li>

                        <li>
                            <a href="blog-1.html">News</a>
                        </li> -->

                    </ul>

                    <div class="copy-right-text text-two">
                        <div class="row align-items-center">
                            <div class="col-lg-6">
                                <p>Copyright
                                    <i class="flaticon-copyright" id="demo"></i> 
                                    OFROH All rights reserved. 
                                 </p>
                            </div>

                            <div class="col-lg-6">
                                 <ul>
                                    <li>
                                        <a href="http://www.oceansofttech.net/" target="_blank">
                                            Created by Ocean Software Technologies
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="footer-shape">
                <div class="shape-1">
                    <img src="assets/img/footer/1.png" alt="image">
                </div>

                <div class="shape-2">
                    <img src="assets/img/footer/2.png" alt="image">
                </div>
            </div>

        
        </section>
        <!-- End Footer Area -->
           <!-- Start Go Top Section -->
           <div class="go-top">
            <i class="bx bx-chevron-up"></i>
            <i class="bx bx-chevron-up"></i>
        </div>
        <!-- End Go Top Section -->
