import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { AuthGuard } from '../services/auth-guard.service';
import { AboutDetailComponent } from './about-detail/about-detail.component';
import { BememberComponent } from './bemember/bemember.component';
import { DefaultComponent } from './default/default.component';
import { GallaryHeaderComponent } from './gallary-header/gallary-header.component';
import { ImageGallaryComponent } from './image-gallary/image-gallary.component';
import { ListbydistrictComponent } from './listbydistrict/listbydistrict.component';
import { MemberComponent } from './member/member.component';
import { NewsEventComponent } from './news-event/news-event.component';
import { PaymentDashboardComponent } from './payment-dashboard/payment-dashboard.component';
import { RootComponent } from './root/root.component';
import { VideoGallaryComponent } from './video-gallary/video-gallary.component';
import { SelectByMemberdateComponent } from './select-by-memberdate/select-by-memberdate.component';
import { PaymentInfoInsertComponent } from './payment-info-insert/payment-info-insert.component';
import { PaidListComponent } from './paid-list/paid-list.component';
import { RenewalListComponent } from './renewal-list/renewal-list.component';
import { SerchbydistrictComponent } from './serchbydistrict/serchbydistrict.component';

const routerOptions:ExtraOptions={
  scrollPositionRestoration:'enabled',
  anchorScrolling:'enabled'
};

const routes: Routes = [
  {
    path:"dashboard",
    component: RootComponent,
    children: [
      { path:'',component:DefaultComponent},
      { path:'AboutDetail',component:AboutDetailComponent},
      { path:'GallaryHeader',component:GallaryHeaderComponent},
      { path:'ImageGallary',component:ImageGallaryComponent},
      { path:'Member',component:MemberComponent},
      { path:'NewsEvent',component:NewsEventComponent},
      { path:'VideoGallary',component:VideoGallaryComponent},
      { path:'PaymentDashboard',component:PaymentDashboardComponent},
      { path:'Bemember',component:BememberComponent},
      { path:'List-By-District',component:ListbydistrictComponent},
      { path:'ListByDistrict', component:SerchbydistrictComponent},
      { path:'SelectByMemberdate', component:SelectByMemberdateComponent},
      { path:'PaymentInfoInsertComponent',component:PaymentInfoInsertComponent },
      { path:'paidlist', component:PaidListComponent},
      { path:'renewallist', component:RenewalListComponent},
    ],
     canActivate:[AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
