    <!-- Start About Area -->
    <section class="about-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
              <div *ngFor="let data of AboutList">
                  <div [innerHTML]="data.discription">{{data.discription}}</div>
              </div>
            </div>
        </div>

       
    </section>
    <!-- End About Area -->
