import { Component, OnInit } from '@angular/core';
import { from } from 'rxjs';
import { MainService } from 'src/app/services/main.service';
import{DomSanitizer} from '@angular/platform-browser'

@Component({
  selector: 'app-video-gallary',
  templateUrl: './video-gallary.component.html',
  styleUrls: ['./video-gallary.component.css']
})
export class VideoGallaryComponent implements OnInit {
  Title:string="Video Gallary";
  GallaryList:any;
  Url: any;
  videoPath:any;
  videogallary:any={};
  RunSqls: any;
  constructor(private mainService:MainService, public sanitizer: DomSanitizer) { }
 
  ngOnInit(){
    this.VideoGallery_Select_Display();
  }

  VideoGallery_Select() {
    var Moobj: any = {};
    Moobj.Pid ='dd5bbfc0-f6c1-4d5b-9fb5-072039f953d4';
    Moobj.PkVideoGallery=0;
    this.mainService.TwoMethod(Moobj).then(value => {
      this.GallaryList = value;
      this.GallaryList = this.GallaryList.table;
      // for(let i=0;i<=this.GallaryList.table;i++)
      // {
      //   this.videogallary.videoPath=this.GallaryList.table[i].videoPath
      //   console.log( this.videogallary.videoPath)
      //   this.Url = this.sanitizer.bypassSecurityTrustUrl(this.videogallary.videoPath);
      // }
    });
  }
  /*  */
  VideoGallery_Select_Display() {
    var Moobj: any = {};
    Moobj.Pid ='9daa3732-241a-46b1-a4fb-dbf893e8db264';
    this.mainService.TwoMethod(Moobj).then(value => {
      this.RunSqls = value;
      this.RunSqls=this.RunSqls.table[0].result;
    alert( JSON.stringify (this.RunSqls))
      this.RunSql();
     
    });
    
  }

  RunSql() {debugger;
    var col = [];
    for (var i = 0; i < Object.keys(this.RunSqls).length; i++) {
      for (var key in this.RunSqls[i]) {
        if (col.indexOf(key) === -1) {
          col.push(key);
        }
      }
    }
    // CREATE DYNAMIC TABLE.
    var table = document.createElement("table");

    // CREATE HTML TABLE HEADER ROW USING THE EXTRACTED HEADERS ABOVE.
    var tr = table.insertRow(-1);                   // TABLE ROW.
    for (var i = 0; i < col.length; i++) {
      var th = document.createElement("th");      // TABLE HEADER.
      th.innerHTML = col[i];
      tr.appendChild(th);
    }
    // ADD JSON DATA TO THE TABLE AS ROWS.
    for (var i = 0; i < this.RunSqls.length; i++) {
      tr = table.insertRow(-1);

      for (var j = 0; j < col.length; j++) {
        var tabCell = tr.insertCell(-1);
        tabCell.innerHTML = this.RunSqls[i][col[j]];
      }
    }

    // FINALLY ADD THE NEWLY CREATED TABLE WITH JSON DATA TO A CONTAINER.
    var divContainer = document.getElementById("showData");
    divContainer.innerHTML = "";
    divContainer.appendChild(table);
  }
  /*  */
}
