<!-- <app-home-title [title]="Title" [message]="Title" [home]="home"></app-home-title> -->
   <!-- Start Experience Area -->
   <section class="experience-section ptb-100">
    <div class="container">
        <div class="row align-items-center bg-color">
            <div class="col-lg-6 p-0">
                <div class="experience-image">
                    <img src="assets/img/human-right.jpg" alt="image">
                </div> 
            </div>

            <div class="col-lg-6 p-0">
                <div class="experience-content ">
                    <h3>Organization For Rights of Human</h3>
                    <p class="text"> 
                        Maharashtra is an organization of    socially  backward class  employees abbreviated as OFROH,  registered under Trade Unions Act -1926 in the year 2020.  It is formed in the sant nagari Shegaon under the blessing of shri sant Gajanan Maharaj Shegaon on 2/2/2020.. It has branches almost all districts in the state of Maharashtra  i.e. Nagpur ,Chandrapur, Bhandara, Gadchiroli,  Wardha,  Yeotmal, Washim, Gondia,  Amravati, akola, Buldhana, Jalgaon,  Jalna,  Nashik,  Solapur,Dhule, Nandurbar, Pune, kolhapur, satara, sangli, Raigad,  Ratnagiri, Sindhudurga, Palghar, Beed, Parbhani, Hingoli, osmanabad, Nanded,  Aurangabad, Thane,Mumbai.  </p>
                    <p>The main objective of the organization is to safeguard and to protect the human rights and to propagate the rights  of human beings entrusted by manifesto of  National Commission for Human Rights,   international Commission of Human Rights and Constitution of India.</p>
                    
                    <!-- <div class="experience-btn">
                        <a href="pricing.html" class="default-btn">
                            Know Details
                            <span></span>
                        </a>

                        <a href="#" class="optional-btn">
                            Buy Tickets
                        </a>
                    </div> -->
                </div>
            </div>
        </div>
    </div>


</section>
<!-- End Experience Area -->

