import { Component, OnInit, ViewChild } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MainService } from 'src/app/services/main.service';
const URL ='https://hrtft.com/api/cipher/UPLOAD';

@Component({
  selector: 'app-news-event',
  templateUrl: './news-event.component.html',
  styleUrls: ['./news-event.component.css']
})
export class NewsEventComponent implements OnInit {
  EventList:any;
  public editorValue: string = '';
  ImageResult: any;
  Token: string = localStorage.getItem('auth_token');
   str: string;
   @ViewChild('fileInput') fileInput;
   public uploader: FileUploader = new FileUploader({ url: URL, itemAlias: 'photo', authToken: `Bearer ${this.Token}`, additionalParameter: { 'PkSystemUploadDocSetting': '{"IsCipher":"1","ApplicationId":"2008","PkSystemUploadDocSetting":1}' } });
  //  text: any;
   ImageResult1: string;
  SpResult: any;
  CategoryList: any;

  constructor(private mainService:MainService,private fb:FormBuilder) { }
  Eventform:FormGroup;
  ngOnInit() {
     /* Form Initialization */
    this.Eventform=this.fb.group({
      PkEvent:['',Validators.required],
      Description:['',Validators.required],
      PhotoPath:['',Validators.required],
      imageUpload:[''],
      FileName:['']
    })
      /* Image Uploader */
    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      // console.log('ImageUpload:uploaded:', item, status, response);
      this.str = response;
      this.ImageResult = this.str.replace("\"", "");
      this.str = response;
      this.ImageResult1 = this.str.replace("\"", "");
      this.ImageResult1 = this.ImageResult1.replace("{'Result': '1','FileName':'", "");
      this.ImageResult1 = this.ImageResult1.replace(",'}", "");
      // console.log( this.ImageResult1)
      this.Eventform.controls['PhotoPath'].setValue(this.ImageResult1);
    }
  }
    
     /* CRUD Operation Start */
     NewsMaster_Insert(){
       var Moobj:any = {}; 
        Moobj.Pid ='76139ffb-218b-4d3b-8c70-ac62ad0e91e1';
        Moobj.Description=this.Eventform.value.Description;
        Moobj.NewsImage=this.Eventform.value.PhotoPath;
        this.mainService.OneMethod(Moobj).then(value=>{
        this.SpResult=value;
        this.EventList= this.SpResult.table;
        console.log(  this.EventList)
        alert(JSON.stringify( this.SpResult.table[0].column2));
       });
         this.Reset()
      }
      
      NewsMaster_Select(){ 
       var Moobj:any = {}; 
       Moobj.Pid='146bff90-9aab-43b6-9b4d-88dc4efee870';
       Moobj.PkNewsMaster=0;
       this.mainService.OneMethod(Moobj).then(value=>{
         this.CategoryList=value;
         this.CategoryList=this.CategoryList.table;
        });
     }   
      
     NewsMaster_Update(){
       var Moobj:any = {}; 
       Moobj.Pid='782f7658-dc50-42ec-919d-827d79ec3536';
       Moobj.PkNewsMaster=this.Eventform.get('PkEvent').value;
       Moobj.Description=this.Eventform.value.Description;
       this.mainService.OneMethod(Moobj).then(value=>{
         this.SpResult=value;
         alert(JSON.stringify( this.SpResult.table[0].column1));
         this.NewsMaster_Select();
       });
       this.Reset();
     }
      
     NewsMaster_Delete(){ 
       var Moobj:any = {}; 
       Moobj.Pid='41b81d03-114d-46cf-89a1-8c193083805d';
       Moobj.PkNewsMaster=this.Eventform.get('PkEvent').value;
       this.mainService.OneMethod(Moobj).then(value=>{
       this.SpResult=value;
       alert(JSON.stringify( this.SpResult.table[0].column1));
       this.NewsMaster_Select(); 
       });
       this.Reset()
       }
 
      Reset(){
       this.Eventform.reset();
      }
     
     RowSelected(u:any){
       this.Eventform.controls['PkEvent'].setValue(u.id);
       this.Eventform.controls['Description'].setValue(u.description);
       // this.Imageform.controls['Name'].setValue(u.galleryHeader);
       this.Eventform.controls['PhotoPath'].setValue(u.newsImage);
      
 
       window.scroll({ 
       top: 0
      });
     }
     /* CRUD Operation End */
  }


