import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-sponsers',
  templateUrl: './home-sponsers.component.html',
  styleUrls: ['./home-sponsers.component.css']
})
export class HomeSponsersComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  
  }
  clients=[
    {name:"शिवानंद सहारकर ",post:"राज्याध्यक्ष  "},
    {name:"राजेश सोनपराते   ",post:"कार्याध्यक्ष   "},
    {name:" प्रा. देवराम  नंदनवार ",post:"उपाध्यक्ष"},
    {name:"रुपेश पाल  ",post:"महासचिव"},
    {name:"मनीष पंचगाम   ",post:"कोषाध्यक्ष "},
    {name:" प्रा. बी. के. हेडाऊ  ",post:"निमंत्रक"},
    {name:"डाँ. अनंत पाटील    ",post:"सहसचिव"},
    {name:"मनोज जुनोनकर   ",post:"कायदे सल्लागार"},
    {name:" गजेंद्र पौनीकर   ",post:"प्रसिद्धी प्रमुख"},
    {name:"दत्तात्रय अन्नमवाड    ",post:"कार्यकारी सदस्य"},
    {name:"ओमप्रकाश कोटरवार    ",post:"कार्यकारी सदस्य"},
    {name:"अनिलकुमार ढोले  ",post:"कायदे सल्लागार   "}

  ]
  clientcarouselOptions= {
    items: 8,
    margin: 80,
    autoHeight: true,
    nav: false,
    dots: false,
    autoplay: true,
    slideSpeed: 300,
    paginationSpeed: 400,
    loop: true,
    responsive: {
        0: {
            items: 1,
            margin: 10
        },
        320:{
            items: 2,
            margin: 20
        },
        480:{
            items: 3,
            margin: 10
        },
        576:{
            items: 4,
            margin: 0
        },
        768:{
            items: 6,
            margin: 30
        },
        992:{
            items: 7,
            margin: 30
        },
        1200: {
            items: 8,
            margin: 50
        }
    }
  }
}
