 <!-- Start Banner Area -->
 <div class="main-banner-eight">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="main-banner-content">
                            <!-- <span>the biggest annual  event</span> -->
                            <h1>Be A  Volunteer</h1>
                           
                            <!-- <div class="banner-btn">
                                <a href="pricing.html" class="default-btn">
                                    Buy ticket online
                                    <span></span>
                                </a>
                            </div> -->
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="banner-form">
                            <h3>Fill Up The Form</h3>
    
                            <form>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Name">
                                </div>
    
                                <div class="form-group">
                                    <input type="email" class="form-control" placeholder="Email">
                                </div>

                                <div class="form-group">
                                    <input type="email" class="form-control" placeholder="Phone">
                                </div>
    
                                <div class="form-group">
                                    <textarea cols="30" rows="6" class="form-control" placeholder="Message"></textarea>
                                </div>
    
                                <button type="submit">Send a Request</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Banner Area -->
