<app-home-title></app-home-title>
<!-- Start Inner Privacy Policy Area -->
<section class="privacy-policy pt-100 pb-160">
    <div class="container">
        <div class="single-privacy">
            <h3 class="mt-0">Disclaimer</h3>
            <p>The information on this this site is meant for the use of general purpose only. Any information posted or delivered by RTD Research as in (our, us, we) on this site is intended to help readers/marketers/businesses with the supply of knowledgeable content about the B2B industry. The content promoted on this site gives a detailed overview of our products and services that can help customers make informed decisions.</p>

            <p>We do not support, publish, or share any information that is false, illegitimate, or derogatory. However, in doing so, we do not claim that the information readers get on our site are entirely accurate, valid, reliable or authentic as it is based on research done across various sources. Hence, under any circumstances, we shall not be held responsible for any loss/damage or impact caused by the content of this site to any individual/group/institution/organization.</p>

            <p>When you use this site or the content available here, you do it at your discretion, and anyhow we are not responsible for the same.</p>

            <p>Moreover, as a global site, we may have links to third-party websites or content which may belong to any third-party source. However, we shall still not be accountable or responsible for any of the content available on those links that one may find on this site. While going through our content, remember that it is not meant or generated for use as</p><br>
            •	an endorsement, advice, or recommendation of any kind especially with regards to financial matters,<br>
            •	health, legal, professional, or medical information. We request our readers to use their, understanding, and sensibility before blindly following any idea, concept, topics, projection, or aspiration as may have been discussed on this website.<br>

            <p>Kindly note: Do not forget the limitations, policies and disclaimer notes issued with regards to use of any content, product, services, and advice offered or available on this site.</p>



        </div>
    </div>
    
 
</section>
<!-- End Inner Privacy Policy Area -->
