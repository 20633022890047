    <!-- Start Navbar Area -->
    <div class="navbar-area">
        <div class="wezet-responsive-nav">
            <div class="container">
                <div class="row">
                    <div class="col-6">
                       <div class="logo wezet-responsive-nav">
                        <a class="navbar-brand" href="/home">
                            <img src="assets/img/logo.png" class="white-logo" alt="logo">
                            <img src="assets/img/logo.png" class="black-logo" alt="logo">
                        </a>
                       </div>
                    </div>
                   <!--  -->
                    <div class="col-3 "></div>
                    <div class="col-3 ">
                         <div class="w3-sidebar w3-bar-block w3-animate test"    style="display:none;z-index:5" id="mySidebar">
                             <button class="w3-bar-item w3-button w3-large" (click)="w3_close()">Close &times;</button>
                           <a routerLink="/home" class="w3-bar-item w3-button">Home</a>
                           <a routerLink="/event" class="w3-bar-item w3-button">Events</a>
                           <a routerLink="/gallary" class="w3-bar-item w3-button"> Gallary</a>
                           <!-- <a routerLink="/bemember" class="w3-bar-item w3-button">Be a Member</a> -->
                           <a routerLink="/login" class="w3-bar-item w3-button">Login</a>
                        </div>
                          <div class="w3-overlay w3-animate-opacity"  
                             (click)="w3_close()"style="cursor:pointer" id="myOverlay">
                          </div>
                         <div>
                            <button class="w3-button w3-white w3-xxlarge" (click)="w3_open()">&#9776;</button>
                         </div>
                    </div>
                 </div>
                <!--  -->
            </div>
        </div>
        <!--  -->
           <!--  --> 
        <div class="wezet-nav">
            <div class="container">
                <nav class="navbar navbar-expand-md navbar-light">
                    <a class="navbar-brand" href="/home">
                        <img src="assets/img/logo.png" class="white-logo" alt="logo">
                        <img src="assets/img/logo.png" class="black-logo" alt="logo">
                    </a>

                    <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                        <ul class="navbar-nav">
                            <li class="nav-item">
                                <a routerLink="/home" class="nav-link">
                                    Home 
                                </a>
                               
                            </li>
                            <li class="nav-item">
                                <a routerLink="/about" class="nav-link">
                                    About Us
                                </a>
                            </li>


                            <li class="nav-item">
                                <a routerLink="/event" class="nav-link">
                                    Events 
                                </a>
                            </li>
                            <li class="nav-item">
                                <a routerLink="/gallary" class="nav-link">
                                   Gallary
                                </a>
                            </li>

                         
                            <!-- <li class="nav-item">
                                <a routerLink="/bemember" class="nav-link">
                                   Be a Member
                                </a>
                            </li> -->
                          
                        </ul>

                       
                        <div class="nav-btn">
                            <a routerLink="/login" class="default-btn">
                               Login
                                <span></span>
                            </a>
                        </div>
                       
<span  class="spacer"></span>
                    </div>
                </nav>
            </div>
        </div>
    </div>
    <!-- End Navbar Area -->
