import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-about-detail',
  templateUrl: './about-detail.component.html',
  styleUrls: ['./about-detail.component.css']
})
export class AboutDetailComponent implements OnInit {

  submitted = false;
  AboutList:any;
  CategoryList: any;
  SpResult:any;
  response:any={};
  public editorValue: string = '';
  constructor(private mainService:MainService,private fb:FormBuilder) { }
   agendaform:FormGroup;

  ngOnInit() {
    this.agendaform=this.fb.group({
      PkAgenda:['',Validators.required],
      Description:['',Validators.required],
    })
  }
/* CRUD operation Start */
  Insert()
  {
    debugger;
    var Moobj:any = {}; 
    Moobj.Pid ='dfed6d46-5543-4ca2-8509-718e020227f1';
    Moobj.Discription=this.agendaform.value.Description;
    this.mainService.OneMethod(Moobj).then(value=>{
    this.SpResult=value;
    this.AboutList= this.SpResult.table;
    console.log(  this.AboutList)
    alert(JSON.stringify( this.SpResult.table[0].column2));
    });
      this.Reset()
  }
  
  Select(){ 
    var Moobj:any = {}; 
    Moobj.Pid='bfd7c0f6-4f35-4250-abbf-f55e822bea29';
    this.mainService.TwoMethod(Moobj).then(value=>{
      this.CategoryList=value;
      this.CategoryList=this.CategoryList.table;
     });
  }   

  Update(){
    var Moobj:any = {}; 
    Moobj.Pid='e931dffc-c6be-4d24-8693-42fa819c98db';
    Moobj.PkAboutUs=this.agendaform.get('PkAgenda').value;
    Moobj.Discription=this.agendaform.value.Description;
    this.mainService.TwoMethod(Moobj).then(value=>{
      this.SpResult=value;
      alert(JSON.stringify( this.SpResult.table[0].column1));
      this.Select();
    });
    this.agendaform.reset()
    }
  

 Delete(){ 
  var Moobj:any = {}; 
  Moobj.Pid='4e5c52ae-849a-4977-b7cd-35a8fea1b5e6';
  Moobj.PkAboutUs=this.agendaform.get('PkAgenda').value;
  this.mainService.TwoMethod(Moobj).then(value=>{
  this.SpResult=value;
  alert(JSON.stringify( this.SpResult.table[0].column1));
  this.Select(); 
  });
  this.agendaform.reset()
  }
  
Reset(){
  this.agendaform.reset();
  
 }

RowSelected(u:any){
  this.agendaform.controls['PkAgenda'].setValue(u.pkAboutUs);
  this.agendaform.controls['Description'].setValue(u.discription);
  window.scroll({ 
  top: 0
 });
}
/* CRUD operation End */

}
