import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatPaginatorModule } from '@angular/material/paginator';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { RootComponent } from './root/root.component';
import { AboutDetailComponent } from './about-detail/about-detail.component';
import { ImageGallaryComponent } from './image-gallary/image-gallary.component';
import { VideoGallaryComponent } from './video-gallary/video-gallary.component';
import { NewsEventComponent } from './news-event/news-event.component';
import { MemberComponent } from './member/member.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CKEditorModule } from 'ngx-ckeditor';
import { GallaryHeaderComponent } from './gallary-header/gallary-header.component';
import { FileUploadModule } from 'ng2-file-upload'
import { AuthGuard } from '../services/auth-guard.service';
import { PaymentDashboardComponent } from './payment-dashboard/payment-dashboard.component';
import { BememberComponent } from './bemember/bemember.component';
import { ListbydistrictComponent } from './listbydistrict/listbydistrict.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DefaultComponent } from './default/default.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
// ------------------MAT----------------------
import {MatCommonModule} from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import {MatRippleModule} from '@angular/material/core';
import { SelectByMemberdateComponent } from './select-by-memberdate/select-by-memberdate.component';
import { PaymentInfoInsertComponent } from './payment-info-insert/payment-info-insert.component';
import { RenewalListComponent } from './renewal-list/renewal-list.component';
import { PaidListComponent } from './paid-list/paid-list.component';
import { SerchbydistrictComponent } from './serchbydistrict/serchbydistrict.component';
export const MY_MOMENT_FORMATS = {
  fullPickerInput: { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' },
  datePickerInput: { year: 'numeric', month: 'numeric', day: 'numeric' },
  monthYearLabel: { year: 'numeric', month: 'short' },
  dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
  monthYearA11yLabel: { year: 'numeric', month: 'long' },
};
@NgModule({
  declarations: [RootComponent,
    AboutDetailComponent,
    ImageGallaryComponent,
    VideoGallaryComponent,
    NewsEventComponent,
    MemberComponent,
    GallaryHeaderComponent, PaymentDashboardComponent, BememberComponent, ListbydistrictComponent, DefaultComponent, SelectByMemberdateComponent, PaymentInfoInsertComponent, RenewalListComponent, PaidListComponent, SerchbydistrictComponent],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    CKEditorModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    BrowserAnimationsModule,
    FileUploadModule,
    MatPaginatorModule,
    NgbModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MatInputModule,
    MatCommonModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatIconModule,
    MatSelectModule,
    MatRippleModule,
    MatMomentDateModule
  ],
  providers: [AuthGuard, { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }]
})
export class DashboardModule { }
