import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OperatorRoutingModule } from './operator-routing.module';
import { OperatornavigationComponent } from './operatornavigation/operatornavigation.component';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../services/auth-guard.service';
import { AuthrizationService } from '../services/auth.service';
import { MainService } from '../services/main.service';
import { ApiConfigService } from '../services/api-config.service';
import { OptBememberComponent } from './opt-bemember/opt-bemember.component';
import { NewsComponent } from './news/news.component';
import { GallaryComponent } from './gallary/gallary.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FileUploadModule } from 'ng2-file-upload';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CKEditorModule } from 'ngx-ckeditor';
// ------------------MAT----------------------

import { MatCommonModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRippleModule } from '@angular/material/core';
@NgModule({
  declarations: [OperatornavigationComponent, OptBememberComponent, NewsComponent, GallaryComponent],
  imports: [
    CommonModule,
    OperatorRoutingModule,
    HttpClientModule,
    HttpModule,
    CKEditorModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    BrowserAnimationsModule,
    FileUploadModule,
    MatPaginatorModule,
    NgbModule,

    MatInputModule,
    MatCommonModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatIconModule,
    MatSelectModule,
    MatRippleModule
  ],
  providers: [AuthGuard, AuthrizationService, MainService, ApiConfigService],

})
export class OperatorModule { }
