<div class="w3-sidebar w3-bar-block w3-animate-left" style="display:none;z-index:5" id="mySidebar">
    
    <button class="w3-bar-item w3-button w3-large" (click)="w3_close()">Close &times;</button>
    <a routerLink="bemember" class="w3-bar-item w3-button">Bemember</a>
    <a routerLink="news" class="w3-bar-item w3-button">News</a>
    <a routerLink="gallary" class="w3-bar-item w3-button">Gallary</a>
    <a (click)="logout()" class="w3-bar-item w3-button">Logout</a>
  </div>

  <div class="w3-overlay w3-animate-opacity" (click)="w3_close()" style="cursor:pointer" id="myOverlay"></div>
<div>
  <button class="w3-button w3-white w3-xxlarge" (click)="w3_open()">&#9776;</button>
</div>

<router-outlet></router-outlet>