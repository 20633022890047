import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthrizationService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-operatornavigation',
  templateUrl: './operatornavigation.component.html',
  styleUrls: ['./operatornavigation.component.css']
})
export class OperatornavigationComponent implements OnInit {

  public openSide : boolean = false;
  public overlay: boolean = false;
  constructor(private authservice:AuthrizationService, private router:Router) { }

  ngOnInit(): void {
  }
//open sidenav
openNav(){
  document.getElementById("mySidenav").style.width = "250px";
 }
 //close sidenav
 closeNav(){
  document.getElementById("mySidenav").style.width = "0";
  }

w3_open() {
  document.getElementById("mySidebar").style.display = "block";
  document.getElementById("myOverlay").style.display = "block";
}

w3_close() {
  document.getElementById("mySidebar").style.display = "none";
  document.getElementById("myOverlay").style.display = "none";
}

logout(){
 this.authservice.logout();
 this.router.navigate(['/home'])
 }
}
