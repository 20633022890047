import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/services/main.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-payment-info-insert',
  templateUrl: './payment-info-insert.component.html',
  styleUrls: ['./payment-info-insert.component.css']
})
export class PaymentInfoInsertComponent implements OnInit {
  public editorValue: string = '';
  memberform: FormGroup;
  SpResult: any;
  MoObj: any = {};
  List: any = [];
  List2: any;
  Id: any;
  datePipe: any;
  retrievedObject: any;
  DatebyId: string;
  isShow: boolean = false;

  constructor(private mainService: MainService, private fb: FormBuilder) { }
  ngOnInit() {
    this.memberform = this.fb.group({
      MemberID: new FormControl('', [Validators.required]),
      // fkmemberform: new FormControl(''),
      paymentdescription: new FormControl(''),
      amount: new FormControl('', [Validators.required]),
      pdate: new FormControl(''),
      transactionid: new FormControl('', [Validators.required]),
    });
  }
 
  
  clear() {
    this.memberform = this.fb.group({
      amount: [''],
      transactionid: [''],
      pdate: [''],
      paymentdescription: [''],
    });
  }
  keyPressAlpha(event) {
    var inp = String.fromCharCode(event.keyCode);
    if (/[0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  keyPressNumbersDecimal(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  MemberForm_SelectPaymentDetailsBy_Pk() {
    this.MoObj.PkMemberForm = this.memberform.value.MemberID;
    localStorage.setItem('PkMemberForm', this.MoObj.PkMemberForm);
    this.retrievedObject = localStorage.getItem('PkMemberForm');
    // alert(this.retrievedObject)
    this.MoObj.Pid = "d6eaf851-efb2-4522-aa4b-ee20bb80a866";
    this.mainService.TwoMethod(this.MoObj).then(value => {
      this.SpResult = value;
      this.List = this.SpResult.table
      // this.List = this.SpResult.table[1]
      // console.log(this.List);
      this.List2 = this.SpResult.table1
      // console.log(this.List2);
      // alert(JSON.stringify(this.SpResult));
      // this.memberform.controls['Name'].setValue(this.SpResult.table.name);
    });
    
  
  }
  PaymentInfo_Insert() {
    // debugger;

    this.MoObj.Pid = "36748bd1-da77-42c3-a9ca-235944a4396c";
    this.MoObj.FkMemberFrom = this.retrievedObject;
    this.MoObj.PaymentDescription = this.memberform.value.paymentdescription;
    this.MoObj.Amount = this.memberform.value.amount;
    this.MoObj.PDate = this.memberform.value.pdate;
    this.MoObj.TransactionID = this.memberform.value.transactionid;
    this.mainService.TwoMethod(this.MoObj).then(value => {
      this.SpResult = value;
      alert(JSON.stringify(this.SpResult.table[0].column2))
      // console.log(this.SpResult);
      // alert("Submit successfully");
    });
    this.MemberForm_SelectPaymentDetailsBy_Pk()
    //  this.MemberForm_SelectPaymentDetailsBy_Pk()
    // this.memberform.reset();
    // location.reload();
  }
  get f() { return this.memberform.controls; }

  toggleShow() {
    this.isShow = !this.isShow;
  }
  onSubmit(){
    if (this.memberform.valid){
      console.log("Form Submitted!");
      this.memberform.reset();
    }
  }
}



