import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
 
  public openSide : boolean = false;
  public overlay: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }
  //open sidenav
  openNav(){
   document.getElementById("mySidenav").style.width = "350px";
  }
  //close sidenav
  closeNav(){
   document.getElementById("mySidenav").style.width = "0";
   }

 w3_open() {
   document.getElementById("mySidebar").style.display = "block";
   document.getElementById("myOverlay").style.display = "block";
 }
 
w3_close() {
   document.getElementById("mySidebar").style.display = "none";
   document.getElementById("myOverlay").style.display = "none";
 }
}
