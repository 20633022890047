import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-gallary',
  templateUrl: './gallary.component.html',
  styleUrls: ['./gallary.component.css']
})
export class GallaryComponent implements OnInit {

  GallaryList:any;
  Title:string="Galleries";
  home:string="Home";
  constructor(private mainService:MainService) { }
 
  ngOnInit(){
    this.GalleryMaster_Select_display();
  }

  GalleryMaster_Select_display() {
    var Moobj: any = {};
    Moobj.Pid ='0a0498b9-6818-4bd0-b326-e66c4643ba41';
    Moobj.PkGalleryMaster=0;
    this.mainService.TwoMethod(Moobj).then(value => {
      this.GallaryList = value;
      this.GallaryList = this.GallaryList.table;
      console.log( this.GallaryList)
    });
  }
}
