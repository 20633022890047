import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-events',
  templateUrl: './home-events.component.html',
  styleUrls: ['./home-events.component.css']
})
export class HomeEventsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
