
<section>
    <div class="container">
        <div class="Wrapper">
            <div class="register-form">
         <h3 style="color: black;text-align: center;font-style: normal;">Add Image Gallary</h3>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
   
     <form [formGroup]="Memberform">
   
                 <div class="form-group row">
                      <label class="col-sm-3 col-form-label">ID</label>
                        <div class="col-sm-7">
                            <input  class="form-control" formControlName="Pkmember">
                        </div>
                 </div>  

                 <div class="form-group row">
                      <label for="inputPassword" class="col-sm-3 col-form-label">ठिकाण</label>
                        <div class="col-sm-7">
                           <input class="form-control" id="inputPassword"formControlName="Fkdistrict" >
                         </div>
                  </div>

                  <div class="form-group row">
                    <label for="inputPassword" class="col-sm-3 col-form-label">अर्जदार/सभासद/देणगीदार नाव </label>
                      <div class="col-sm-7">
                         <input class="form-control" id="inputPassword"formControlName="Name" >
                       </div>
                  </div>

                  <div class="form-group row">
                    <label for="inputPassword" class="col-sm-3 col-form-label">पूर्ण पत्ता</label>
                      <div class="col-sm-7">
                         <input class="form-control" id="inputPassword"formControlName="Address" >
                       </div>
                  </div>

                  <div class="form-group row">
                    <label for="inputPassword" class="col-sm-3 col-form-label">मो. न</label>
                      <div class="col-sm-7">
                         <input class="form-control" id="inputPassword"formControlName="MobNo" >
                       </div>
                  </div>

                  <div class="form-group row">
                    <label for="inputPassword" class="col-sm-3 col-form-label">ई-मेल</label>
                      <div class="col-sm-7">
                         <input class="form-control" id="inputPassword"formControlName="Email" >
                       </div>
                  </div>

                  <div class="form-group row">
                    <label for="inputPassword" class="col-sm-3 col-form-label">नौकरी</label>
                      <div class="col-sm-7">
                         <input class="form-control" id="inputPassword"formControlName="Job" >
                       </div>
                  </div>

                  <div class="form-group row">
                    <label for="inputPassword" class="col-sm-3 col-form-label">कार्यालयाचं ठिकाण</label>
                      <div class="col-sm-7">
                         <input class="form-control" id="inputPassword"formControlName="JobPlace" >
                       </div>
                  </div>

                  <div class="form-group row">
                    <label for="inputPassword" class="col-sm-3 col-form-label">कार्यालयातील पद</label>
                      <div class="col-sm-7">
                         <input class="form-control" id="inputPassword"formControlName="Post" >
                       </div>
                  </div>
     
     
     
      <!-- <div class="row">
       <div class="col-sm-2"  >
         <button type="submit" class="btn btn-primary"           
         (click)="Insert()">Submit</button>
        </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <div class="col-sm-2"  >
         <button type="submit" class="btn btn-primary" 
         (click)="Update()">update</button>
            </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <div  class="col-sm-2" ><button type="submit" class="btn btn-primary" 
          (click)="Delete()">Delete</button>
         </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <div  class="col-sm-2" ><button type="submit" class="btn btn-primary" 
         (click)="Select()">Select</button>
       </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <div  class="col-sm-2" > <button type="submit" class="btn btn-primary" 
         (click)="Reset()">Reset</button>
       </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </div> -->
     </form>
    </div>
  </div>
 </div>

</section>
<!-- 
<div class="container">    
    <div class="table-responsive">   
    <table class="table" id="tblList">
      <thead>
        <tr>
          <th>ID</th>
         
          <th>Agenda Name</th>
          <th>Agenda Description</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let cmp of CategoryList;">
          <td (click)="RowSelected(cmp)">{{cmp.pkAgenda}}</td>
       
          <td>{{cmp.agendaName}}</td>
          <td [innerHTML]="cmp.agendaDescription">{{cmp.agendaDescription}}</td>
        
        </tr> 
      </tbody>
    </table>
  </div>
  </div> -->


