<div class="login-section ptb-30">
    <div class="container" id="mytop">
        <div class="login-form">
            <div class="login-title">
                <h3>Paid List</h3>
            </div>
            <form [formGroup]="paidform" id="home" novalidate>
                <div class="form-group col-lg-4" id="ValidityDate">Select Date</div>
                <div class="col-lg-8">
                    <mat-form-field appearance="fill">
                        <mat-label>Choose a date</mat-label>
                        <input matInput [matDatepicker]="picker" id="DateOfJoining" placeholder=""
                            formControlName="ChequeDate">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="row inline-block col-12">
                    <div class="col-sm-2">
                        <button type="submit" id="btnsave" class="default-btn" (click)="getMember()" >Show</button>
                    </div>
                    <div *ngIf='loading2' id="myModal" class="modal">
                        <div class="lds-roller">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>


                    <div class="col-sm-2">
                        <button type="button" title="XL" id="btnexcel" class="default-btn" style="width: 130px" (click)="exportexcel()"><svg
                                xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                class="bi bi-file-earmark-excel" viewBox="0 0 16 16"></svg> Excel</button>
                        &nbsp;
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="container">
    <div class="table-responsive">
        <table class="table" id="excel-table">
            <thead>
                <tr>
                    <th>SR.No.</th>
                    <th>Member ID</th>
                    <th>Name / District</th>
                    <th>Payment Date</th>
                    <th>payment Discription</th>
                    <th>Transaction ID</th>
                    
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let memberList of SpResult">
                    <tr>

                        <td>{{memberList.srNo}}</td>
                        <td>{{memberList.memberID}}</td>
                        <td>{{memberList.name}}</td>
                        <td>{{memberList.paymentDate}}</td>
                        <td>{{memberList.paymentDescription}}</td>
                        <td>{{memberList.transactionID}}</td>
                        <!-- <td {{memberList.dName}}></td> -->
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>