<div class="login-section ptb-30">
    <div class="container" id="mytop">
        <div class="login-form">
            <div class="login-title">
                <h3>Payment Renewal</h3>
            </div>
            <form [formGroup]="memberform" id="home" (ngSubmit)="onSubmit()">
                <div class="row">
                    <div class="col-lg-12 form-inline">
                        <div class="form-group col-lg-4">Member ID<div style="color:red;">*</div>
                        </div>
                        <div class="col-lg-8">
                            <input type="text" (keypress)="keyPressAlpha($event)" id="txtPassNumber" class="form-control"onkeyup="EnableDisable(this)" 
                                style="width:50%;" placeholder="Please Enter Member ID" formControlName="MemberID"
                                required/>
                            <div *ngIf="f.MemberID.invalid && f.MemberID.touched">
                                <span class="text-danger" *ngIf="f.MemberID.errors?.required">
                                    Enter Transaction Id.
                                </span>
                            </div>
                        </div>

                        <div class="row inline-block col-12">
                            <div class="col-sm-4">
                                <button type="submit" id="btnSubmit" class="default-btn"
                                    (click)="MemberForm_SelectPaymentDetailsBy_Pk()" (click)="toggleShow()" >Show Data</button>
                            </div>
                        </div>
                    </div>
                </div>


                <h2>Member Information</h2>
                <div class="container">
                    <table style="width: 100%">
                        <colgroup>
                            <col span="1" style="width: 5%;">
                            <col span="1" style="width: 5%;">
                            <col span="1" style="width: 5%;">
                            <col span="1" style="width: 5%;">
                            <col span="1" style="width: 5%;">
                            <col span="1" style="width: 5%;">
                            <col span="1" style="width: 5%;">
                        </colgroup>

                        <thead>
                            <tr>
                                <th scope="col"> No.</th>
                                <th scope="col">Member Id</th>
                                <th scope="col">Name</th>
                                <th scope="col">Address</th>
                                <th scope="col">Mob No</th>
                                <th scope="col">Email </th>
                                <th scope="col">Member Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of List">
                                <td innerHTML="{{item.srNo}}">{{item.srNo}}</td>
                                <td innerHTML="{{item.memberID}}">{{item.memberID}}</td>
                                <td innerHTML="{{item.name}}">{{item.name}}</td>
                                <td innerHTML="{{item.address}}">{{item.address}}</td>
                                <td innerHTML="{{item.mobNo}}">{{item.mobNo}}</td>
                                <td innerHTML="{{item.email}}">{{item.email}}</td>
                                <td innerHTML="{{item.memberDate}}">{{item.memberDate | date:'yyyy-MM-dd' }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <h2>Payment Information</h2>
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col"> PaymentInfo</th>
                            <th scope="col"> PDate</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Transaction Id</th>
                            <th scope="col">Payment Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of List2">
                            <td innerHTML="{{item.pkPaymentInfo}}">{{item.pkPaymentInfo}}</td>
                            <td innerHTML="{{item.pDate}}">{{item.pDate | date:'yyyy-MM-dd' }}</td>
                            <td innerHTML="{{item.amount}}">{{item.amount}}</td>
                            <td innerHTML="{{item.transactionID}}">{{item.transactionID}}</td>
                            <td innerHTML="{{item.paymentDescription}}">{{item.paymentDescription}}</td>
                        </tr>
                    </tbody>
                </table>



                <div *ngIf="isShow" id="divshow">

                    <div class="col-lg-12 align-items-left">
                        <div class="mb-3">
                            <label for="amount" class="form-label">Amount</label>
                            <input type="amount" (keypress)="keyPressNumbersDecimal($event)" class="form-control"
                                style="width:30%;" id="name" formControlName="amount" required />
                            <div *ngIf="f.amount.invalid && f.amount.touched">
                                <span class="text-danger" *ngIf="f.amount.errors?.required">
                                    Enter Amount.
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 align-items-left">
                        <div class="mb-3">
                            <label for="transaction id" class="form-label">Transaction Id</label>
                            <input type="text" class="form-control" style="width:30%;" id="Transaction id"
                                formControlName="transactionid" aria-describedby="emailHelp">
                            <div *ngIf="f.transactionid.invalid && f.transactionid.touched">
                                <span class="text-danger" *ngIf="f.transactionid.errors?.required">
                                    Enter Transaction Id.
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="form-group ">
                            <mat-form-field appearance="fill">
                                <mat-label>PDate</mat-label>
                                <input matInput [matDatepicker]="picker" formControlName="pdate" id="date"
                                    placeholder="Select Date">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>

                    <!-- <ck-editor name="editor1" formControlName="paymentdescription" skin="moono-lisa" language="en" [fullPage]="true"></ck-editor> -->
                    <div class="col-lg-12 align-items-left">
                        <div class="mb-3">
                            <label for="payment" class="payment">Payment Description:</label> <br>
                            <textarea style="width:350px; height:80px;" formControlName="paymentdescription" cols="42"
                                rows="5" name="payment"></textarea>
                        </div>
                    </div>


                    <button type="submit" class="btn btn-primary" (click)="PaymentInfo_Insert()" (click)="toggleShow()"
                        [disabled]="!memberform.valid">Submit</button>
                    <button type="submit" class="btn btn-primary">Update</button>
                    <button type="submit" class="btn btn-primary">Delete</button>
                    <button type="submit" class="btn btn-primary">Cancel</button>
                    <button type="submit" class="btn btn-primary">Exit</button>
                </div>
            </form>
        </div>
    </div>
</div>