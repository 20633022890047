import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {

  constructor(private mainService:MainService) { }
  EventList:any;
  Title:string="Events";

  ngOnInit() {
    this.NewsMaster_Select();
  }

  NewsMaster_Select() {
       var Moobj: any = {};
       Moobj.Pid ='a015b2f6-7105-4850-9076-e0836a814f8c';
       Moobj.PkNewsMaster=0;
      this.mainService.TwoMethod(Moobj).then(value => {
      this.EventList = value;
      this.EventList = this.EventList.table;
    });
  }
}
