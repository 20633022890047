<div class="container" style="text-align: center;">  
<h4>Payment List</h4>
</div>
<br><br>
<div class="container">    
    <div class="table-responsive"> 
       
    </div>
</div>
<div class="container">    
    <div class="table-responsive">   
        <div class="col-lg-3 col-md-6">
            <select  class="form-control" (change)=" PaymentInfo_Select($event)">
              <option  *ngFor="let data of Status" [value]="data.value" id="status">{{data.name}}</option>
            </select>
        </div>          
    <table class="table" id="tblList">
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Address</th>
          <th>Mob No</th>
          <th>Amount</th>
          <th>District</th>
          <th>Status</th>
          <th>MOJO_Id</th>
          <th>Transaction_Id</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let cmp of eventList;">
          <td >{{cmp.name}}</td>
          <td>{{cmp.email}}</td>
          <td [innerHTML]="cmp.address">{{cmp.address}}</td>
          <td >{{cmp.mobNo}}</td>
          <td >{{cmp.amount}}</td>
          <td>{{cmp.name1}}</td>
          <td >{{cmp.pStatus}}</td>
          <td >{{cmp.mojoTransID}}</td>
          <td >{{cmp.transactionID}}</td>
        </tr> 
      </tbody>
    </table>
  </div>
  </div>
