import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-gallary-header',
  templateUrl: './gallary-header.component.html',
  styleUrls: ['./gallary-header.component.css']
})
export class GallaryHeaderComponent implements OnInit {

  public editorValue: string = '';
  HeaderList:any;
  SpResult:any;
  CategoryList:any;
  constructor(private mainService:MainService,private fb:FormBuilder) { }
  GallaryHeaderform:FormGroup;
  ngOnInit() {
    this.GallaryHeaderform=this.fb.group({
      PkHeader:['',Validators.required],
      Name:['',Validators.required],
    })
    /*  */
    /*  */
  }
/* CRUD Operation Start */
GalleryHeaderMaster_Insert()
{
  var Moobj:any = {}; 
  Moobj.Pid ='2d47575c-bdc4-44f1-995a-f7823e17ae39';
  Moobj.GalleryHeader=this.GallaryHeaderform.value.Name;
  this.mainService.OneMethod(Moobj).then(value=>{
  this.SpResult=value;
  this.HeaderList= this.SpResult.table;
  console.log(  this.HeaderList)
  alert(JSON.stringify( this.SpResult.table[0].column2));

  });
    this.Reset()
}

GalleryHeaderMaster_Select(){ 
  var Moobj:any = {}; 
  Moobj.Pid='fbed3590-7a4f-4eee-9202-80ccd8e392451';
  this.mainService.OneMethod(Moobj).then(value=>{
    this.CategoryList=value;
    this.CategoryList=this.CategoryList.table;
   });
}   

GalleryHeaderMaster_Update(){
  var Moobj:any = {}; 
  Moobj.Pid='501e1e28-5584-4870-aa4d-56d025a4daab';
  Moobj.PkGalleryHeaderMaster=this.GallaryHeaderform.get('PkHeader').value;
  Moobj.GalleryHeader=this.GallaryHeaderform.value.Name;
  this.mainService.OneMethod(Moobj).then(value=>{
    this.SpResult=value;
    alert(JSON.stringify( this.SpResult.table[0].column1));
    this.GalleryHeaderMaster_Select();
  });
  this.Reset();
  }

  GalleryHeaderMaster_Delete(){ 
    var Moobj:any = {}; 
    Moobj.Pid='3e2a2d05-f22e-4d21-9039-82834897d3b4';
    Moobj.PkGalleryHeaderMaster=this.GallaryHeaderform.get('PkHeader').value;
    this.mainService.OneMethod(Moobj).then(value=>{
    this.SpResult=value;
    alert(JSON.stringify( this.SpResult.table[0].column1));
    this.GalleryHeaderMaster_Select(); 
    });
    this.Reset()
    
    }
Reset(){
  this.GallaryHeaderform.reset();
 }

RowSelected(u:any){
  this.GallaryHeaderform.controls['PkHeader'].setValue(u.id);
  this.GallaryHeaderform.controls['Name'].setValue(u.galleryHeader);
  window.scroll({ 
  top: 0
 });
}
/* CRUD Operation End */
}
