import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-goverment',
  templateUrl: './goverment.component.html',
  styleUrls: ['./goverment.component.css']
})
export class GovermentComponent implements OnInit {
Title:string="Goverment";
home:string="Home"
  constructor() { }

  ngOnInit(): void {
  }

}
