import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {
  Title:string="About-Us"
  AboutList:any
  constructor(private mainService:MainService) { }

  ngOnInit(){
  }
  AboutUs_Select_Display() {
    var Moobj: any = {};
    Moobj.Pid ='bfd7c0f6-4f35-4250-abbf-f55e822bea29';
    this.mainService.TwoMethod(Moobj).then(value => {
      this.AboutList = value;
      this.AboutList = this.AboutList.table;
      console.log( this.AboutList)
    });
  }
}


