import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GallaryComponent } from './gallary/gallary.component';
import { NewsComponent } from './news/news.component';
import { OperatornavigationComponent } from './operatornavigation/operatornavigation.component';
import { OptBememberComponent } from './opt-bemember/opt-bemember.component';


const routes: Routes = [
  {
    path:"operator",  component:OperatornavigationComponent,
    children: [
      
      { path:'operatornavigation', component:OperatornavigationComponent },
      {path:'bemember', component:OptBememberComponent},
      {path:'news',component:NewsComponent},
      {path:'gallary',component:GallaryComponent}
      
    ]}   
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OperatorRoutingModule { }
