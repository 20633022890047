   <!-- Start Sponsorship Section -->
   <div class="sponsorship-section pt">
    <div class="container">
        <div class="section-title">
            <h2>  FOUNDER MEMBERS</h2>
            <!-- <h6>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</h6> -->
        </div>
        <div class="wrapper">
            <owl-carousel-o [options]="clientcarouselOptions">
          
              <ng-container *ngFor="let data of clients">
                <ng-template  carouselSlide >
                  <!-- <img src={{data.img}}  alt="data-slider"  > -->
              
                  <div>
                    <h6 ><b>{{data.name}}</b></h6><br>
                    <h6>{{data.post}}</h6>
                    <!-- <h6 class="post text-center  font-primary">{{user.designation}}</h6> -->
                </div>
                </ng-template>
              </ng-container>
          
            </owl-carousel-o>  
          </div>
     
    </div>
</div>
<!-- End Sponsorship Section -->

