import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-listbydistrict',
  templateUrl: './listbydistrict.component.html',
  styleUrls: ['./listbydistrict.component.css']
})
export class ListbydistrictComponent implements OnInit {
  StateList:any;
  DistrictList:any;
  memberform:FormGroup;
  SpResult:any;
  District:any;
  bememberList:any;
  constructor(private mainService: MainService, private fb:FormBuilder) { }

  ngOnInit(): void {
    this.memberform = this.fb.group({
      District:[''],
      CentralState:[''],
    })
    this.State_DDL();
    this.list();
  }

  Insert() { 
  
    var Moobj: any = {};
    Moobj.Pid = 'c397267c-8b81-4728-8548-9300a7b2d891';
    this.mainService.TwoMethod(Moobj).then(value => {
      this.SpResult = value;
      this.bememberList = this.SpResult.table;
      console.log(this.bememberList)
      alert(JSON.stringify(this.bememberList));
    });
  }
  State_DDL(){
    var Moobj: any = {};
    Moobj.Pid ='235ddd46-8db7-4fba-96b2-3ea465234e61';
   this.mainService.TwoMethod(Moobj).then(value => {
   this.StateList = value;
   this.StateList = this.StateList.table;
   console.log(this.StateList);
 });
 
  }
  District_DDL_Select() { debugger
    var Moobj: any = {};
    Moobj.Pid ='d343c4db-01f0-46a9-a604-c4395e880664';
     Moobj.FkState=this.memberform.get('CentralState').value;
     console.log(Moobj.FkState);
     this.mainService.TwoMethod(Moobj).then(value => {
      this.DistrictList = value;
      this.DistrictList = this.DistrictList.table;
    });
   }
   list(){ debugger
    var MoObj : any={}
    MoObj.Pid="88b93eb1-68e0-4ab4-a995-9d533815af98";
    MoObj.FkDistrict=this.memberform.get('District').value;
    // alert(JSON.stringify(MoObj))
      this.mainService.TwoMethod(MoObj).then(value=>{
      this.SpResult=value;
      this.SpResult=this.SpResult.table
      console.log(JSON.stringify(this.SpResult))
      });

  }
  changeDistrict(e) {debugger
    // console.log(JSON.stringify(this.memberform.get('District').value)+"this is id");
     this.District=this.memberform.get('District').value;
     this.list();
    }
    
}
