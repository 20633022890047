import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions, RequestMethod } from '@angular/http';
import { map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  errors:any;
  constructor(private http: Http){ }
  test(MoObj:any) {
    var obj = MoObj;
    var body = JSON.stringify(obj);
    let headers = new Headers();
     //headers.append('Access-Control-Request-Method', 'POST');
    // headers.append('Access-Control-Request-Headers', 'Content-Type, Accept');
    //  headers.append('Access-Control-Allow-Origin', '*');
    //  headers.append('vary','Origin');
    headers.append('Content-Type','application/json');
    headers.append('X-Api-Key','23b7456f381ca8bcc8140df2ed4ea378');
    headers.append('X-Auth-Token','7ca6d1e7396334ee223d35689f7f2204');
    let options = new RequestOptions({ headers: headers }); 
    return this.http.post( 'https://www.instamojo.com/api/1.1/payment-requests/', body, options,)
      .pipe(map((data: Response) => { 
        return data.json();
      }))
    }

    OneMethod(MoObj: any) {
      return new Promise(resolve=>{
       this.test(MoObj)
        .subscribe(
            result => {
              resolve(result);
             },
             error => this.errors = error);
          })
      }

      Payment(url:any) {
        let headers = new Headers();
        // headers.append('Access-Control-Request-Method', 'GET');
        // headers.append('Access-Control-Request-Headers', 'Content-Type, Accept');
        // headers.append('Access-Control-Allow-Origin', '*');
        headers.append('Content-Type','application/json');
        headers.append('X-Api-Key','23b7456f381ca8bcc8140df2ed4ea378');
        headers.append('X-Auth-Token','7ca6d1e7396334ee223d35689f7f2204');
        let options = new RequestOptions({ headers: headers }); 
        return this.http.get( url, options,)
          .pipe(map((data: Response) => { 
            return data.json();
          }))
        }
        TwoMethod(url:any) {
          return new Promise(resolve=>{
           this.Payment(url)
            .subscribe(
                result => {
                  resolve(result);
                 },
                 error => this.errors = error);
          })
        }
  


}
