import { Injectable } from '@angular/core';
import { BaseService } from './base.service'
import { ApiConfigService } from './api-config.service'
import { Http, Response, Headers, RequestOptions, RequestMethod } from '@angular/http';
import { map} from 'rxjs/operators';
import * as SecureLS from 'secure-ls';
 const  url="https://serverengg.oceansofttech.net/api/ZION/SENDMAIL";
@Injectable({
  providedIn: 'root'
})
export class MainService extends BaseService {
/** */
  public products: any=[]; // new variable declared on 2020-02-13 by nilesh  
  static PageId: any;
  static Pageidmaster: any;  
 
/** */
  baseUrl: string ;
  public errors:string;
  constructor(private http: Http, private configService: ApiConfigService) {
    super();
    this.baseUrl = configService.getApiURI();
  }
  //start working after login//
  objInnerMethod(MoObj: any) {
    MoObj.ApplicationId="2";
    MoObj.IsCipher="1";
    var obj = MoObj;
    var body = JSON.stringify(obj);
    let headers = new Headers();
  //  headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Content-Type', 'application/json'); 
    let authToken = localStorage.getItem('auth_token');
    headers.append('Authorization', `Bearer ${authToken}`);
    let options = new RequestOptions({ headers: headers }); 
    return this.http.post( this.baseUrl+'/cipher/main', body, options,)
      .pipe(map((data: Response) => { 
        return data.json();
      }))
  }
//start working before login//
  objOuterMethod(MoObj: any) {
    MoObj.ApplicationId = "2";
    MoObj.IsCipher = "0";
    var obj = MoObj;
    var body = JSON.stringify(obj);
    let headers = new Headers();
   // headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.baseUrl + "/zion/main", body, options)
      .pipe(map((data: Response) => {
        return data.json();
      }))
  }
  emailMethod(MoObj: any) {
    MoObj.ApplicationId = "2";
    MoObj.IsCipher = "0";
    MoObj.PkSystemEmailSetting=2;
    MoObj.message="Thank you for Subscribe"
    var obj = MoObj;
    var body = JSON.stringify(obj);
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return this.http.post(url, body, options)
      .pipe(map((data: Response) => {
        return data.json();
      }))
  }

  OneMethod(MoObj: any) {
    return new Promise(resolve=>{
     this.objInnerMethod(MoObj)
      .subscribe(
          result => {
            resolve(result);
           },
           error => this.errors = error);
    })
    }

    TwoMethod(MoObj: any) {
      return new Promise(resolve=>{
       this.objOuterMethod(MoObj)
        .subscribe(
            result => {
              resolve(result);
             },
             error => this.errors = error);
      })
      }

}






// import { Injectable } from '@angular/core';
// import { BaseService } from './base.service'
// import { ApiConfigService } from './api-config.service'
// import { Http, Response, Headers, RequestOptions, RequestMethod } from '@angular/http';
// import { map} from 'rxjs/operators';
// import { promise } from 'protractor';

//  const  url="https://serverengg.oceansofttech.net/api/ZION/SENDMAIL";
// @Injectable({
//   providedIn: 'root'
// })
// export class MainService extends BaseService {
// /** */
//   public products: any=[]; // new variable declared on 2020-02-13 by nilesh  
//   static PageId: any;
//   static Pageidmaster: any;  
 
// /** */
//   baseUrl: string ;
//   public errors:string;
//   constructor(private http: Http, private configService: ApiConfigService) {
//     super();
//     this.baseUrl = configService.getApiURI();
//   }
//   //start working after login//
//   objInnerMethod(MoObj: any) {
//     MoObj.ApplicationId="2";
//     MoObj.IsCipher="1";
//     var obj = MoObj;
//     var body = JSON.stringify(obj);
//     let headers = new Headers();
//     headers.append('Content-Type', 'application/json');
//     let authToken = localStorage.getItem('auth_token');
//     headers.append('Authorization', `Bearer ${authToken}`);
//     let options = new RequestOptions({ headers: headers }); 
//     return this.http.post( this.baseUrl+'/cipher/main', body, options,)
//       .pipe(map((data: Response) => { 
//         return data.json();
//       }))
//   }
// //start working before login//
//   objOuterMethod(MoObj: any) {
//     MoObj.ApplicationId = "2";
//     MoObj.IsCipher = "0";
//     var obj = MoObj;
//     var body = JSON.stringify(obj);
//     let headers = new Headers();
//     headers.append('Content-Type', 'application/json');
//     let options = new RequestOptions({ headers: headers });
//     return this.http.post(this.baseUrl + "/zion/main", body, options)
//       .pipe(map((data: Response) => {
//         return data.json();
//       }))
//   }
//   emailMethod(MoObj: any) {
//     MoObj.ApplicationId = "2";
//     MoObj.IsCipher = "0";
//     MoObj.PkSystemEmailSetting=2;
//     MoObj.message="Thank you for Subscribe"
//     var obj = MoObj;
//     var body = JSON.stringify(obj);
//     let headers = new Headers();
//     headers.append('Content-Type', 'application/json');
//     let options = new RequestOptions({ headers: headers });
//     return this.http.post(url, body, options)
//       .pipe(map((data: Response) => {
//         return data.json();
//       }))
//   }

//   OneMethod(MoObj: any) {
//     return new Promise(resolve=>{
//      this.objInnerMethod(MoObj)
//       .subscribe(
//           result => {
//             resolve(result);
//            },
//            error => this.errors = error);
//     })
//     }

//     TwoMethod(MoObj: any) {
//       return new Promise(resolve=>{
//        this.objOuterMethod(MoObj)
//         .subscribe(
//             result => {
//               resolve(result);
//              },
//              error => this.errors = error);
//       })
//       }
     
//       ImageUploader(MoObj: any) {
//         MoObj.ApplicationId="2";
//         MoObj.IsCipher="1";
//         MoObj.PkSystemUploadDocSetting="1"
//         var obj = MoObj;
//         var body = JSON.stringify(obj);
//         let headers = new Headers();
//         headers.append('Content-Type', 'application/json');
//         let authToken = localStorage.getItem('auth_token');
//         headers.append('Authorization', `Bearer ${authToken}`);
//         let options = new RequestOptions({ headers: headers }); 
//         return this.http.post( this.baseUrl+'/cipher/UPLOAD', body, options,)
//           .pipe(map((data: Response) => { 
//             return data.json();
//           }))
//       }


// }
