<app-sub-banner [title]="title"></app-sub-banner>
<!-- Start Login Area -->
<div class="login-section ptb-100">
    <div class="container">
        <div class="login-form">
            <div class="login-title">
                <h3>Welcome Back!</h3>
                <p>Please login to your account.</p>
            </div>
              
                <form [formGroup]="form">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="form-group">
                                <input type="email" class="form-control" placeholder="Email" 
                                  [formControl]="form.controls['email']">
                            </div>
                        </div>
    
                        <div class="col-lg-12">
                            <div class="form-group">
                                <input type="password" class="form-control" placeholder="Password" 
                                [formControl]="form.controls['password']">
                            </div>
                        </div>
    
                        <div class="col-lg-12">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="checkme">
                                <label class="form-check-label" for="checkme">Keep me Log In</label>
                            </div>
                        </div>
    
                        <div class="col-lg-12">
                            <p class="forgot-password text-right"><a routerLink="#">Forgot Password?</a></p>
                        </div>

                    
                    
                        <div class="news-btn">
                            <a (click)="login('f')" class="default-btn">Log In Now</a>
                        </div>  <br>
                        <span>Don't have account? <a routerLink="#">Signup!</a></span>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- End Login Area -->
