
<app-sub-banner [title]="Title"></app-sub-banner>

 <!-- Start News Area -->
        <section class="news-section pt-100 pb-160">
            <div class="container">
               
                <div class="row">
                    <div class="col-lg-4 col-md-6" *ngFor="let eve of EventList">
                        <div class="news-item">
                            <div class="image">
                                <a href="single-blog.html">
                                    <img src={{eve.imagepath}} alt="image" >
                                </a>
                            </div>

                            <div class="content">
                                <p [innerHTML]="eve.description">{{eve.description}}</p>

                                <div class="news-btn">
                                    <a routerLink="#" class="default-btn">Read More</a>
                                </div>
                            </div>
                        </div>
                    </div>

                 

                  
                </div>
            </div>

            
        </section>
        <!-- End News Area -->