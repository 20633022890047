import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatPaginatorModule} from '@angular/material/paginator';

import { HttpClientModule } from '@angular/common/http';
import{HttpModule} from '@angular/http';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import{FormsModule, ReactiveFormsModule} from '@angular/forms'
//import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
// import{CKEditorModule} from '@ckeditor/ckeditor5-build-classic'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';
import { MenuComponent } from './site/menu/menu.component';
import { LoaderComponent } from './site/loader/loader.component';
import { HeaderComponent } from './site/header/header.component';
import { FooterComponent } from './site/footer/footer.component';
import { LayoutComponent } from './site/layout/layout.component';
import { HomeComponent } from './site/home/home.component';
import { HomeTitleComponent } from './site/Components/home-title/home-title.component';
import { HomeAboutComponent } from './site/Components/home-about/home-about.component';
import { HomeCounterComponent } from './site/Components/home-counter/home-counter.component';
import { HomeEventsComponent } from './site/Components/home-events/home-events.component';
import { HomeTestimonialComponent } from './site/Components/home-testimonial/home-testimonial.component';
import { HomeSponsersComponent } from './site/Components/home-sponsers/home-sponsers.component';
import { AboutUsComponent } from './site/about-us/about-us.component';
import { PrivacyPolicyComponent } from './site/privacy-policy/privacy-policy.component';
import { TermsConditionComponent } from './site/terms-condition/terms-condition.component';
import { DisclaimerComponent } from './site/disclaimer/disclaimer.component';
import { ContactComponent } from './site/contact/contact.component';
import { LoginComponent } from './site/login/login.component';
import{AuthGuard} from './services/auth-guard.service';
import{AuthrizationService} from './services/auth.service';
import { MainService } from './services/main.service';
import{ApiConfigService} from './services/api-config.service'
import { GallaryComponent } from './site/gallary/gallary.component';
import { CenterComponent } from './site/center/center.component';
import { GovermentComponent } from './site/goverment/goverment.component';
import { EventsComponent } from './site/events/events.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { MemberFormComponent } from './site/member-form/member-form.component';
import {DpDatePickerModule} from 'ng2-date-picker';
import {DashboardModule} from 'src/app/dashboard/dashboard.module'
import { CKEditorModule } from 'ngx-ckeditor';
import{FileUploadModule} from 'ng2-file-upload'
import { from } from 'rxjs';
import { SubBannerComponent } from './site/Components/sub-banner/sub-banner.component';
import { VideoGallaryComponent } from './site/video-gallary/video-gallary.component';
import { SafePipe } from './site/Components/pipes/safe.pipe';
import{PaymentService} from 'src/app/services/payment.service';
import { FieldErrorDisplayComponent } from './site/Components/field-error-display/field-error-display.component';
import { AppAlphabetOnlyDirective } from './site/Components/app-alphabet-only.directive';
import { InvoiceComponent } from './site/Components/invoice/invoice.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { OperatorModule } from './operator/operator.module';
import { MatTableModule } from '@angular/material/table';
import {MatCommonModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';



// import { DatepickerModule, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
// import { Mat} FOR OWL DATE';

import { CommonModule } from '@angular/common';

// import { OwlDateTimeModule, OwlNativeDateTimeModule } FOR OWL DATE';
import {NgbPaginationModule, NgbAlertModule} from '@ng-bootstrap/ng-bootstrap';

// import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';


@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    LoaderComponent,
    HeaderComponent,
    FooterComponent,
    LayoutComponent,
    HomeComponent,
    HomeTitleComponent,
    HomeAboutComponent,
    HomeCounterComponent,
    HomeEventsComponent,
    HomeTestimonialComponent,
    HomeSponsersComponent,
    AboutUsComponent,
    PrivacyPolicyComponent,
    TermsConditionComponent,
    DisclaimerComponent,
    ContactComponent,
    LoginComponent,
    GallaryComponent,
    CenterComponent,
    GovermentComponent,
    EventsComponent,
    MemberFormComponent,
    SubBannerComponent,
    VideoGallaryComponent,
    SafePipe,
    FieldErrorDisplayComponent,
    AppAlphabetOnlyDirective,
    InvoiceComponent,
    

   
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    HttpModule,
    ReactiveFormsModule,
    CarouselModule,
    NgbModule,
    BrowserAnimationsModule,
    DpDatePickerModule,
    CKEditorModule,
    FormsModule,
    DashboardModule,
   // AngularFirestoreModule,
    CKEditorModule,
    FileUploadModule,
    // BsDatepickerModule.forRoot(),
    // DatepickerModule.forRoot() 
    MatPaginatorModule,
    OperatorModule,
   MatTableModule ,
   MatCommonModule,
   MatDatepickerModule,
   MatFormFieldModule
  ],
  
  providers: [AuthGuard,AuthrizationService,MainService,ApiConfigService,PaymentService, ],
  bootstrap: [AppComponent]
})
export class AppModule { }
