<div class="w3-sidebar w3-bar-block w3-animate-left" style="display:none;z-index:5" id="mySidebar">
    <button class="w3-bar-item w3-button w3-large" (click)="w3_close()">Close &times;</button>
    <a routerLink="Bemember" class="w3-bar-item w3-button">Add Member</a>
    <a routerLink="SelectByMemberdate" class="w3-bar-item w3-button">Search Member in Month</a>
    <a routerLink="PaymentInfoInsertComponent" class="w3-bar-item w3-button">Update Payment</a>
    <a routerLink="paidlist" class="w3-bar-item w3-button">Paid List</a>
    <a routerLink="renewallist" class="w3-bar-item w3-button">Renewal List</a>
    <a routerLink="List-By-District" class="w3-bar-item w3-button">Serch By District</a>
    <a routerLink="ListByDistrict" class="w3-bar-item w3-button">Serch to District</a>
    <!-- <a routerLink="AboutDetail" class="w3-bar-item w3-button">AboutUs Detail</a>
    <a routerLink="GallaryHeader" class="w3-bar-item w3-button">Gallary Header</a>
    <a routerLink="ImageGallary" class="w3-bar-item w3-button">Image Gallary</a>
    <a routerLink="NewsEvent" class="w3-bar-item w3-button">News Event</a>
    <a routerLink="VideoGallary" class="w3-bar-item w3-button">Video Gallary</a> -->
    <a (click)="logout()" class="w3-bar-item w3-button">Logout</a>
  </div>
  <div class="w3-overlay w3-animate-opacity" (click)="w3_close()" style="cursor:pointer" id="myOverlay"></div>
<div>
  <button class="w3-button w3-white w3-xxlarge" (click)="w3_open()">&#9776;</button>
</div>
<router-outlet></router-outlet>
