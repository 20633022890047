import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-home-about',
  templateUrl: './home-about.component.html',
  styleUrls: ['./home-about.component.css']
})
export class HomeAboutComponent implements OnInit {

  AboutList:any
  constructor(private mainService:MainService) { }

  ngOnInit(){
    this. AboutUs_Select_Display() ;
   
    //newStr now contains 'StringIWant'
  }
  AboutUs_Select_Display() {
    var Moobj: any = {};
    Moobj.Pid ='bfd7c0f6-4f35-4250-abbf-f55e822bea29';
    this.mainService.TwoMethod(Moobj).then(value => {
      this.AboutList = value;
      this.AboutList = this.AboutList.table;
      console.log( this.AboutList)
    });
  }


}
