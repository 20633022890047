import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-video-gallary',
  templateUrl: './video-gallary.component.html',
  styleUrls: ['./video-gallary.component.css']
})
export class VideoGallaryComponent implements OnInit {
  SpResult:any;
  VideoList:any;
  CategoryList:any;
  public editorValue: string = '';
  constructor(private mainService:MainService,private fb:FormBuilder) { }
  VideoGallaryform:FormGroup;
  ngOnInit() {
    this.VideoGallaryform=this.fb.group({
      PkVideo:['',Validators.required],
      VideoUpload:['',Validators.required],
      Name:['',Validators.required]

    })
  }


   /* CRUD Operation Start */
   VideoGallery_Insert()
   {
     var Moobj:any = {}; 
      Moobj.Pid ='2b2d388b-4cfb-4c3f-a03e-609e7c7052e5';
      Moobj.VideoName=this.VideoGallaryform.value.Name;
      Moobj.VideoPath=this.VideoGallaryform.value.VideoUpload;
      this.mainService.OneMethod(Moobj).then(value=>{
      this.SpResult=value;
      this.VideoList= this.SpResult.table;
      console.log(  this.VideoList)
      alert(JSON.stringify( this.SpResult.table[0].column2));
     });
       this.Reset()
    }
    
    VideoGallery_Select(){ 
     var Moobj:any = {}; 
     Moobj.Pid='92d24f88-35aa-4e51-abf5-3d3fd789a6cb';
     Moobj.PkVideoGallery=0;
     this.mainService.OneMethod(Moobj).then(value=>{
       this.CategoryList=value;
       this.CategoryList=this.CategoryList.table;
      });
   }   
    
   VideoGallery_Update(){
     var Moobj:any = {}; 
     Moobj.Pid='7772ac42-a9a8-4189-98f1-1cc7846ce271';
     Moobj.PkVideoGallery=this.VideoGallaryform.get('PkVideo').value;
     Moobj.VideoName=this.VideoGallaryform.value.Name;
     Moobj.VideoPath=this.VideoGallaryform.value.VideoUpload;
     this.mainService.OneMethod(Moobj).then(value=>{
       this.SpResult=value;
       alert(JSON.stringify( this.SpResult.table[0].column1));
       this.VideoGallery_Select();
     });
     this.Reset();
   }
    
   VideoGallery_Delete(){ 
     var Moobj:any = {}; 
     Moobj.Pid='b91c3ee9-2d67-4bca-8603-b2c182720c80';
     Moobj.PkVideoGallery=this.VideoGallaryform.get('PkVideo').value;
     this.mainService.OneMethod(Moobj).then(value=>{
     this.SpResult=value;
     alert(JSON.stringify( this.SpResult.table[0].column1));
     this.VideoGallery_Select(); 
     });
     this.Reset()
     }

    Reset(){
     this.VideoGallaryform.reset();
    }
   
   RowSelected(u:any){
     this.VideoGallaryform.controls['PkVideo'].setValue(u.id);
     this.VideoGallaryform.controls['Name'].setValue(u.videoName);
     // this.Imageform.controls['Name'].setValue(u.galleryHeader);
     this.VideoGallaryform.controls['VideoUpload'].setValue(u.videoPath);
    

     window.scroll({ 
     top: 0
    });
   }
   /* CRUD Operation End */
}
