 <!-- Start Features Area -->
 <section class="features-section bg-color ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Event </h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida</p>
        </div>

        <div class="features-slider owl-carousel owl-theme">
            <div class="features-item">
                <div class="icon">
                    <i class="flaticon-microphone"></i>
                </div>
                <h3>Top Speaker</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>

                <div class="number">1</div>
            </div>

            <div class="features-item">
                <div class="icon">
                    <i class="flaticon-group"></i>
                </div>
                <h3>Rsvp Management</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>

                <div class="number">2</div>
            </div>

            <div class="features-item">
                <div class="icon">
                    <i class="flaticon-promotion"></i>
                </div>
                <h3>Promote Reunion</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>

                <div class="number">3</div>
            </div>

            <div class="features-item">
                <div class="icon">
                    <i class="flaticon-project-management"></i>
                </div>
                <h3>Unique Idea</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>

                <div class="number">4</div>
            </div>

            <div class="features-item">
                <div class="icon">
                    <i class="flaticon-microphone"></i>
                </div>
                <h3>Top Speaker</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>

                <div class="number">1</div>
            </div>

            <div class="features-item">
                <div class="icon">
                    <i class="flaticon-group"></i>
                </div>
                <h3>Rsvp Management</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>

                <div class="number">2</div>
            </div>

            <div class="features-item">
                <div class="icon">
                    <i class="flaticon-promotion"></i>
                </div>
                <h3>Promote Reunion</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>

                <div class="number">3</div>
            </div>

            <div class="features-item">
                <div class="icon">
                    <i class="flaticon-project-management"></i>
                </div>
                <h3>Unique Idea</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>

                <div class="number">4</div>
            </div>
        </div>
    </div>


</section>
<!-- End Features Area -->
