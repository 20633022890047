import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sub-banner',
  templateUrl: './sub-banner.component.html',
  styleUrls: ['./sub-banner.component.css']
})
export class SubBannerComponent implements OnInit {

  constructor() { }
  @Input() title:string;
  @Input() home:string;
  @Input() message:string;
  ngOnInit(): void {
  }

}
