

<section>
    <div class="container">
        <div class="Wrapper">
            <div class="register-form">
         <h3 style="color: black;text-align: center;font-style: normal;">Add News & Events</h3>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
   
     <form [formGroup]="Eventform">
   
                 <div class="form-group row">
                      <label class="col-sm-3 col-form-label">ID</label>
                         <div class="col-sm-7">
                             <input  class="form-control" formControlName="PkEvent">
                        </div>
                 </div>

                 <div class="form-group row">
                     <label for="inputPassword" class="col-sm-3 col-form-label">Image Uploader (Insert only 1900*1000 Resolution Images)</label>
                      <div class="col-sm-4">
                         <input type="file" formControlName="imageUpload" ng2FileSelect [uploader]="uploader"/>
                          <button  class="button" (click)="uploader.uploadAll()"
                          [disabled]="!uploader.getNotUploadedItems().length">Upload</button>
                      </div>
                 </div>
                 <div class="form-group row">
                        <label for="inputPassword" class="col-sm-3 col-form-label">ImagePath</label>
                         <div class="col-sm-7">
                           <input class="form-control" id="inputPassword"formControlName="PhotoPath" >
                         </div>
                  </div>
          
                  <div class="form-group row">
                    <label for="inputPassword" class="col-sm-3 col-form-label"> Description</label>
                      <div class="col-sm-7">
                        <ck-editor name="editor1" [(ngModel)]="editorValue" formControlName="Description"
                        skin="moono-lisa" lang="en" [fullPage]="true"></ck-editor>
                      </div>
                  </div>
     
     
          
                  <div class="row">
                    <div class="col-sm-2"  >
                             <button type="submit" class="btn btn-primary"           
                                      (click)="NewsMaster_Insert()">Submit</button>
                    </div>&nbsp;&nbsp;&nbsp;
            
                   <div class="col-sm-2"  >
                              <button type="submit" class="btn btn-primary" 
                                      (click)="NewsMaster_Update()">update</button>
                   </div>&nbsp;&nbsp;&nbsp;
            
                <div  class="col-sm-2" >
                                <button type="submit" class="btn btn-primary" 
                                           (click)="NewsMaster_Delete()">Delete</button>
                   </div>&nbsp;&nbsp;&nbsp;
            
                   <div  class="col-sm-2" >
                                <button type="submit" class="btn btn-primary" 
                                          (click)="NewsMaster_Select()">List</button>
                   </div>&nbsp;&nbsp;&nbsp;
            
                   <div  class="col-sm-2" >
                                  <button type="submit" class="btn btn-primary" 
                                            (click)="Reset()">Reset</button>
                    </div>&nbsp;&nbsp;&nbsp;
               </div>
     </form>
    </div>
  </div>
 </div>

</section>

<div class="container">    
    <div class="table-responsive">   
    <table class="table" id="tblList">
      <thead>
        <tr>
          <th>ID</th>
          <th> Description</th>
          <th> Image</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let cmp of CategoryList;">
          <td (click)="RowSelected(cmp)">{{cmp.id}}</td>
          <td [innerHTML]="cmp.description">{{cmp.description}}</td>
          <td>{{cmp.newsImage}}</td>
          
        
        </tr> 
      </tbody>
    </table>
  </div>
  </div>


