import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MainService } from 'src/app/services/main.service';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-serchbydistrict',
  templateUrl: './serchbydistrict.component.html',
  styleUrls: ['./serchbydistrict.component.css']
})
export class SerchbydistrictComponent implements OnInit {
  StateList:any;
  DistrictList:any;
  memberform:FormGroup;
  SpResult:any;
  District:any;
  bememberList:any;
  fileName = 'ExcelSheet.xlsx';
  constructor(private mainService: MainService, private fb:FormBuilder) { }

  ngOnInit(): void {
    this.memberform = this.fb.group({
      District:[''],
      CentralState:[''],
    })
    this.State_DDL();
  }

  Insert() { 
    var Moobj: any = {};
    Moobj.Pid = 'c397267c-8b81-4728-8548-9300a7b2d891';
    this.mainService.TwoMethod(Moobj).then(value => {
      this.SpResult = value;
      this.bememberList = this.SpResult.table;
      //console.log(this.bememberList)
      alert(JSON.stringify(this.bememberList));
    });
  }

  State_DDL(){debugger
    var Moobj: any = {};
    Moobj.Pid ='235ddd46-8db7-4fba-96b2-3ea465234e61';
   this.mainService.TwoMethod(Moobj).then(value => {
   this.StateList = value;
   this.StateList = this.StateList.table;
   //console.log(this.StateList);
 });
  }

  District_DDL_Select() {debugger
    var Moobj: any = {};
    Moobj.Pid ='d343c4db-01f0-46a9-a604-c4395e880664';
     Moobj.FkState=this.memberform.get('CentralState').value;
     //console.log(Moobj.FkState);
     this.mainService.TwoMethod(Moobj).then(value => {debugger
      this.DistrictList = value;
      this.DistrictList = this.DistrictList.table;
      // alert(JSON.stringify( this.SpResult.table[0].column2));
      //alert(JSON.stringify(this.SpResult[0].fkDistrict))
    });
   }


   list(){debugger
    var MoObj : any={}
    MoObj.Pid="88b93eb1-68e0-4ab4-a995-9d533815af98";
    MoObj.FkDistrict=this.District;
    // alert(JSON.stringify(MoObj))
      this.mainService.TwoMethod(MoObj).then(value=>{
      this.SpResult=value;
      this.SpResult=this.SpResult.table
     // console.log(JSON.stringify(this.SpResult))
      });
  }
  exportexcel(): void {
    /* pass here the table id */
    let element = document.getElementById('tblList');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }

  changeDistrict(e) {debugger
    // console.log(JSON.stringify(this.memberform.get('District').value)+"this is id");
     this.District=this.memberform.get('District').value;
     //alert('pkDistrict' + this.District)
    //  this.list();
    }
    
}
