<div class="login-section ptb-30">
  <div class="container">
    <div class="login-form">
      <form [formGroup]="memberform">
        <div class="row">
          <div class="col-lg-12">
            <div class="form-group"> Central State
              <select class="form-control" formControlName="CentralState" (change)="District_DDL_Select()">
                <option *ngFor="let state of StateList" value={{state.pkState}}>
                  {{state.stateName}}
                </option>
              </select>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="form-group">District
              <select class="form-control" (change)="changeDistrict($event)" formControlName="District">
                <option *ngFor="let dist of DistrictList" value={{dist.pkDistrict}}>
                  {{dist.name}}
                </option>
              </select>

            </div>

          </div>



        </div>

      </form>
    </div>
  </div>
</div>
<!-- End Login Area -->
<div class="container">
  <div class="table-responsive">
    <table class="table" id="tblList">
      <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>

          <th>Mobile No.</th>
          <th>Email</th>
          <th>Address</th>

        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let memberList of SpResult">
          <tr>
            <td>{{memberList.pkMemberForm}}</td>
            <td>{{memberList.name}}</td>

            <td>{{memberList.mobNo}}</td>
            <td>{{memberList.email}}</td>
            <td>{{memberList.address}}</td>

          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>