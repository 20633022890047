import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-member',
  templateUrl: './member.component.html',
  styleUrls: ['./member.component.css']
})
export class MemberComponent implements OnInit {

  constructor(private mainService:MainService,private fb:FormBuilder) { }
  Memberform:FormGroup;
  ngOnInit() {
    this.Memberform=this.fb.group({
      Pkmember:['',Validators.required],
      Fkdistrict:['',Validators.required],
      Name:['',Validators.required],
      Address:['',Validators.required],
      MobNo:['',Validators.required],
      Email:['',Validators.required],
      Job:['',Validators.required],
      JobPlace:['',Validators.required],
      Post:['',Validators.required]
    })
  }
}
