<app-sub-banner [title]="Title"></app-sub-banner>


<!-- Start Login Area -->
<div class="login-section ptb-100">
    <div class="container">
        <div class="login-form">
            <div class="login-title">
                <h3>Application</h3>
                <!-- <p>Please login to your account.</p> -->
            </div>
                <p><b>{{Description}}</b></p>
            <form [formGroup]="memberform">
                <div class="row">
                    <div class="col-lg-12" >
                        <div class="form-group" [ngClass]="displayFieldCss('Name')">Name of the applicant / member / donor
                            <input type="text" class="form-control" placeholder=" " formControlName="Name" appAppAlphabetOnly>
                            <app-field-error-display [displayError]="isFieldValid('Name')" errorMsg="Please inform your name">
                            </app-field-error-display>
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <div class="form-group"  [ngClass]="displayFieldCss('Address')">Full Address
                            <input type="text" class="form-control" placeholder=" " formControlName="Address" appAppAlphabetOnly>
                            <app-field-error-display [displayError]="isFieldValid('Address')" errorMsg="Please inform your Address">
                            </app-field-error-display>
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <div class="form-group" [ngClass]="displayFieldCss('Mobno')">
                            Mobile No
                            <input type="text" class="form-control" placeholder="" formControlName="Mobno">
                            <app-field-error-display [displayError]="isFieldValid('Mobno')" errorMsg="Please fill  your mobNo">
                            </app-field-error-display>
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <div class="form-group" [ngClass]="displayFieldCss('Email')">
                            E-mail
                            <input type="Email" class="form-control" placeholder="" formControlName="Email" appAppAlphabetOnly >
                            <app-field-error-display [displayError]="isFieldValid('Email')" errorMsg="Please fill correct Email">
                            </app-field-error-display>
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <div class="form-group">Date of first appointment
                            <input type="date"  class="form-control" placeholder="" formControlName="FirstAppointmentDate">
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <div class="form-group"> Category
                            <select  type="text" class="form-control" placeholder="" formControlName="Category">
                                <option value="SBC">SBC</option>
                                <option value="OBC">NT</option>
                                <option value="OPEN">OPEN</option>
                                <option value="ST">ST</option>
                                <option value="SC">SC</option>
                                <option value="ST">VJ</option>
                                <option value="SC">DT</option>
                              </select>
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <div class="form-group" [ngClass]="displayFieldCss('FkCaste')">Caste
                            <input type="text" class="form-control" placeholder="" formControlName="FkCaste" appAppAlphabetOnly >
                              <app-field-error-display [displayError]="isFieldValid('FkCaste')" errorMsg="Please fill Caste">
                            </app-field-error-display>
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <div class="form-group">Office post
                            <input type="text" class="form-control" placeholder="" formControlName="Post" appAppAlphabetOnly>
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <div class="form-group"> 
                            Office Address
                            <input type="text" class="form-control" placeholder=" " formControlName="Jobplace" appAppAlphabetOnly>
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <div class="form-group" [ngClass]="displayFieldCss('State')"> State
                            <select class="form-control" formControlName="State" (change)="District_DDL_Select()">
                                <option *ngFor="let state of StateList" value={{state.pkState}}>
                                   {{state.stateName}}
                                </option>
                              </select>
                              <app-field-error-display [displayError]="isFieldValid('State')" errorMsg="Please fill State">
                            </app-field-error-display>
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <div class="form-group" [ngClass]="displayFieldCss('District')"> District
                            <select class="form-control"(change)="changeDistrict($event)" formControlName="District">
                                <option *ngFor="let Dist of DistrictList" 
                                              value={{Dist.pkDistrict}}>
                                   {{Dist.name}}
                                </option>
                              </select>
                              <app-field-error-display [displayError]="isFieldValid('District')" errorMsg="Please fill District">
                            </app-field-error-display>
                        </div>
                    </div>
                    
                    <div class="col-lg-12">
                        <div class="form-group">Caste Certificate
                            <select  type="text" class="form-control" placeholder="" formControlName="CasteValidity" (change)="CastValidity()">
                                <option value="1">Valid</option>
                                <option value="0">Invalid</option>
                                <option value="2">Pending</option>
                                <option value="3">Not Applied</option>
                              </select>
                        </div>
                    </div>

                    <div class="col-lg-6" >
                        <div class="form-group hide" id="ValidityDate">Date
                            <input type="date" class="form-control"  placeholder=" " formControlName="ValidityDate">
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <div class="form-group">Are you a class in the majority position
                            <select  type="text" class="form-control" placeholder="" formControlName="extraFlag">
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                                <option value="2">Not Applicable</option>
                              </select>
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <div class="form-group">Whether you are terminating the service due to invalidity of the certificate
                            <select  type="text" class="form-control" placeholder="" formControlName="JobLoss">
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                              </select>
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <div class="form-group">Did you drop the ST category claim
                            <select  type="text" class="form-control" placeholder="" formControlName="LeftSTCaste" (change)="LeftSTCaste()">
                                <option value=1 >Yes</option>
                                <option value=0 >No</option>
                              </select>
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <div class="form-group hide" id="othercategory">If the claim is dropped, which category is accepted in the job?
                            <select  type="text" class="form-control" placeholder="" formControlName="othercategory">
                                <option value="SBC">SBC</option>
                                <option value="OBC">OBC</option>
                                <option value="OPEN">OPEN</option>
                                <option value="ST">ST</option>
                                <option value="SC">SC</option>
                              </select>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="form-group" >
                            <div class="hide" id="CasteCertificateDate">SBC/NT/OBC 
                                Date of receipt of caste certificate in the category
                            <input type="date" class="form-control" placeholder="" formControlName="CasteCertificateDate">
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <div class="form-group">Which government are you an employee of? 
                            <select  type="text" class="form-control" placeholder="" formControlName="EmployeeOfWhichDeparment"
                             (change)="ServiceCategory()">
                                <option value="State">State Government</option>
                                <option value="Central">Central government</option>
                                <option value="Semi">Semi-government</option>
                              </select>
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <div class="form-group hide" id="CentralState">State
                            <input type="text" class="form-control" placeholder="" formControlName="CentralState" appAppAlphabetOnly>
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <div class="form-group hide" id="CentralDistrict">District
                            <input type="text" class="form-control" placeholder="" formControlName="CentralDistrict" appAppAlphabetOnly>
                        </div>
                    </div>

                  
                 
                    <div class="col-lg-12">
                        <div class="form-group"> 
                           Reference Person 1
                            <input type="text" class="form-control" placeholder=" " formControlName="person1" appAppAlphabetOnly>
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <div class="form-group"> 
                            Reference Person 2
                            <input type="text" class="form-control" placeholder=" " formControlName="person2" appAppAlphabetOnly>
                        </div>
                    </div>

                  
                    
                        <div class="news-btn">
                            <a (click)="Member_Insert()"class="default-btn">SUBMIT & PAY</a>
                        </div>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- End Login Area -->

