<div class="login-section ptb-30">
    <div class="container" id="mytop">
        <div class="login-form">
            <div class="login-title">
                <h3>Create Member</h3>
            </div>
            <p><b>{{Description}}</b></p>
            <form [formGroup]="memberform" id="home" novalidate>
                <div class="row">
                    <div class="col-lg-12 form-inline">
                        <div class="form-group col-lg-4" hidden>Id of the applicant
                            <div class="col-lg-8"><input type="text" class="form-control" disabled placeholder=" "
                                    formControlName="Pkmember" appAppAlphabetOnly></div>

                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 form-inline">
                        <div class="form-group col-lg-4">Name of the member<div style="color:red;">*</div>
                        </div>
                        <div class="col-lg-8"><input type="text" class="form-control" style="width:100%;"
                                placeholder=" " formControlName="Name" appAppAlphabetOnly required>
                            <div *ngIf="f.Name.invalid && f.Name.touched">
                                <small class="text-danger" *ngIf="f.Name.errors?.required">
                                    Please Enter the Name!
                                </small>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 form-inline">
                        <div class="form-group col-lg-4">Full Address<div style="color:red;">*</div>
                        </div>
                        <div class="col-lg-8"> <input type="text" class="form-control" style="width:100%;"
                                placeholder=" " formControlName="Address" appAppAlphabetOnly required>
                            <div *ngIf="f.Address.invalid && f.Address.touched">
                                <small class="text-danger" *ngIf="f.Address.errors?.required">
                                    Please Enter the Address!
                                </small>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 form-inline">
                        <div class="form-group col-lg-4">Mobile No<div style="color:red;">*</div>
                        </div>
                        <div class="col-lg-8"> <input type="text" (keypress)="keyPressmobile($event)"
                                class="form-control" style="width:100%;" placeholder=" " formControlName="Mobno"
                                appAppAlphabetOnly required maxlength="10" minlength="1">
                            <div *ngIf="f.Mobno.invalid && f.Mobno.touched">
                                <small class="text-danger" *ngIf="f.Mobno.errors?.required">
                                    Please Enter the Mobile Number(only Number)!
                                </small>
                                <small class="text-danger" *ngIf="f.Mobno.errors?.pattern">
                                    Please only 10 Digit No Format!
                                </small>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 form-inline">
                        <div class="form-group col-lg-4">E-mail<div style="color:red;">*</div>
                        </div>
                        <div class="col-lg-8">
                            <input type="Email" class="form-control" style="width:100%;" placeholder=""
                                formControlName="Email" required>
                            <div *ngIf="f.Email.invalid && f.Email.touched">
                                <small class="text-danger" *ngIf="f.Email.errors?.required">
                                    Please Enter Email!
                                </small>
                                <small class="text-danger" *ngIf="f.Email.errors?.pattern">
                                    Please Enter Valid Email!
                                </small>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 form-inline">
                        <div class="form-group col-lg-4">Caste</div>
                        <div class="col-lg-8"><select class="form-control col-lg-12" formControlName="FkCaste">
                                <option *ngFor="let Caste of castdropdownlist" value={{Caste.pkCaste}}>
                                    {{Caste.caste}}
                                </option>
                            </select>
                        </div>

                    </div>
                    <div class="col-lg-12 form-inline">
                        <div class="form-group col-lg-4">Designation
                        </div>
                        <div class="col-lg-8"> <input type="text" class="form-control" style="width:100%;"
                                placeholder=" " formControlName="Designation" appAppAlphabetOnly>
                        </div>
                    </div>
                    <div class="col-lg-12 form-inline">
                        <div class="form-group col-lg-4">Job place & Address</div>
                        <div class="col-lg-8"><input type="text" class="form-control" style="width:100%;" placeholder=""
                                formControlName="Jobplace">
                        </div>
                    </div>
                    <div class="col-lg-12 form-inline">
                        <div class="form-group col-lg-4">State<div style="color:red;">*</div></div>
                        <div class="col-lg-8"> <select class="form-control" formControlName="CentralState"
                            style="width:50%;" (change)="District_DDL_Select()" disabled>
                            <option *ngFor="let state of State" selected disabled [selected]="state.pkState"
                                value={{state.pkState}}>
                                {{state.stateName}}
                            </option>
                        </select>
                        </div>

                    </div>
                    <div class="col-lg-12 form-inline">
                        <div class="form-group col-lg-4">District<div style="color:red;">*</div></div>
                        <div class="col-lg-8"> <select class="form-control" (change)="changeDistrict($event)" 
                            style="width:50%;" formControlName="District" disabled>
                            <option *ngFor="let Dist of DistrictList" [selected]="Dist.pkDistrict"
                                value={{Dist.pkDistrict}}>
                                {{Dist.name}}
                            </option>
                        </select>
                        </div>

                    </div>
                    <div class="col-lg-12 form-inline">
                        <div class="form-group col-lg-4">Validity</div>
                        <div class="col-lg-8"> <select type="text" class="form-control" style="width:50%;"
                                placeholder="" formControlName="Validityflag" appAppAlphabetOnly>
                                <option value="2">Pending</option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </select>
                        </div>

                    </div>
                    <div class="col-lg-12 form-inline">
                        <div class="form-group col-lg-4">Type of service</div>
                        <div class="col-lg-8"> <select type="text" class="form-control" style="width:50%;"
                                placeholder="" formControlName="Typesofservices">
                                <option value="State Government">State Government</option>
                                <option value="Central Government">Central Government</option>
                                <option value="Autonomous">Autonomous</option>
                                <option value="Semi Government">Semi Government</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>

                    </div>
                    <div class="col-lg-12 form-inline">
                        <div class="form-group col-lg-4">Caste Validity Office Address</div>
                        <div class="col-lg-8"><input type="text" class="form-control" style="width:100%;"
                                placeholder=" " formControlName="CasteValidityOfficeAddress" appAppAlphabetOnly>
                        </div>
                    </div>
                    <div class="col-lg-12 form-inline">
                        <div class="form-group col-lg-4">Supernumerary Post?</div>
                        <div class="col-lg-8"><select type="text" class="form-control" style="width:50%;" placeholder=""
                                formControlName="AreYouExtra">
                                <option value="2">Not Applicable</option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </select>
                        </div>

                    </div>
                    <div class="col-lg-12 form-inline">
                        <div class="form-group col-lg-4">Job Loss Due To Validity</div>
                        <div class="col-lg-8"><select type="text" class="form-control" style="width:50%;" placeholder=""
                                formControlName="JobLossDueToValidity">
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </select>
                        </div>

                    </div>
                    <div class="col-lg-12 form-inline">
                        <div class="form-group col-lg-4">Are You In ST Category?</div>
                        <div class="col-lg-8"><select type="text" class="form-control" style="width:50%;" placeholder=""
                                formControlName="AreYouInSt" (change)="setReplyTypeValue()">
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </select>
                        </div>

                    </div>
                    <div class="col-lg-12 form-inline">
                        <div class="form-group col-lg-4">Other Category</div>
                        <div class="col-lg-8"><select type="text" class="form-control" style="width:50%;" placeholder=""
                                formControlName="otherCategory">
                                <option value="SBC">SBC</option>
                                <option value="NT">NT</option>
                                <option value="OBC">OBC</option>
                                <option value="OPEN">OPEN</option>
                                <option value="ST">ST</option>
                                <option value="SC">SC</option>
                                <option value="VJ">VJ</option>
                                <option value="DT">DT</option>
                            </select>
                        </div>

                    </div>
                    <div class="col-lg-12 form-inline">
                        <div class="form-group col-lg-4" id="ValidityDate">Adhar Card Photo</div>
                        <div class="col-lg-8"><input type="file" class="form-control" style="width:50%;" ng2FileSelect
                                [uploader]="uploader" (change)="uploader.uploadAll()" placeholder=""
                                formControlName="AdharPhotoPath" name="imageUpload">
                        </div>

                    </div>
                    <div class="col-lg-12 form-inline">
                        <div class="form-group col-lg-4" id="ValidityDate">Photo</div>
                        <div class="col-lg-8"> <input type="file" class="form-control" style="width:50%;" ng2FileSelect
                                [uploader]="uploader1" (change)="uploader1.uploadAll()" placeholder=""
                                formControlName="PhotoPath" name="imageUpload">
                        </div>

                    </div>
                    <div class="col-lg-12 form-inline">
                        <div class="form-group col-lg-4">Cheque No</div>
                        <div class="col-lg-8"> <input type="text" text="-" class="form-control" style="width:100%;"
                                placeholder="" formControlName="ChequeNo">
                        </div>

                    </div>
                    <div class="col-lg-12 form-inline">
                        <div class="form-group col-lg-4" id="ValidityDate">Transaction Date</div>
                        <div class="col-lg-8">
                            <mat-form-field appearance="fill">
                                <mat-label>Choose a date</mat-label>
                                <input matInput [matDatepicker]="picker" id="DateOfJoining"
                                placeholder="" formControlName="ChequeDate" >
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                              </mat-form-field>
                        </div>
                        <div class="col-lg-12 form-inline">
                            <div class="form-group col-lg-4" id="ValidityDate">Extra Description</div>
                            <div class="col-lg-8">
                                <input type="text" class="form-control" style="width:100%;" placeholder=""
                                    formControlName="ExtraDescription">
                            </div>

                        </div>
                        <div class="col-lg-12 form-inline">
                            <div class="form-group col-lg-4" id="ValidityDate">Member Date</div>
                            <div class="col-lg-8">
                                <mat-form-field appearance="fill">
                                    <mat-label>Choose a date</mat-label>
                                    <input type=text matInput [matDatepicker]="pickerMember" id="MemberDate"
                                    formControlName="MemberDate" (ngModelChange)="onChangeMemberDate($event)">
                                    <mat-datepicker-toggle matSuffix [for]="pickerMember"></mat-datepicker-toggle>
                                    <mat-datepicker #pickerMember></mat-datepicker>
                                  </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <br /><br />

                    <div class="row inline-block col-12">
                        <div class="col-sm-2">
                            <button type="submit" id="btnsave" class="default-btn" [disabled]="!memberform.valid"
                                [hidden]="hidebuttonsave" (click)="Insert()" >Save</button>
                        </div>&nbsp;&nbsp;&nbsp;
                      
                        <div class="col-sm-2">
                            <button type="submit" id="btnlist" class="default-btn" [hidden]="hidebuttonlist"
                                (click)="list()">List</button>
                        </div>&nbsp;&nbsp;&nbsp;
                        <div class="col-sm-2">
                            <button type="button" title="XL" id="btnexcel" class="default-btn" (click)="exportexcel()"
                                [hidden]="hidebuttonexcel" style="width: 130px"><svg xmlns="http://www.w3.org/2000/svg"
                                    width="16" height="16" fill="currentColor" class="bi bi-file-earmark-excel"
                                    viewBox="0 0 16 16">
                                    <path
                                        d="M5.884 6.68a.5.5 0 1 0-.768.64L7.349 10l-2.233 2.68a.5.5 0 0 0 .768.64L8 10.781l2.116 2.54a.5.5 0 0 0 .768-.641L8.651 10l2.233-2.68a.5.5 0 0 0-.768-.64L8 9.219l-2.116-2.54z" />
                                    <path
                                        d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                                </svg> Excel</button>&nbsp;&nbsp;&nbsp;
                           
                        </div>
                        <div class="col-sm-2">
                            <button type="button" id="btncancel" class="default-btn" 
                            (click)="realodPage()">Cancel</button>
                        </div>
                       
                       

                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- End Login Area -->
<div class="container">
    <div class="table-responsive">
        <table class="table" id="tblList" [hidden]="tblListhide">
            <thead>
                <tr>
                    <th>SR.No.</th>
                    <th>Member ID</th>
                    <th>Name</th>

                    <th>Mobile No.</th>
                    <th>Designation</th>
                    <th>Cheque No</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let memberList of SpResult " >
                    <tr (click)="Rowselected(memberList)" class="tableRow" onclick="window.scrollTo(0, 0);">
                        <td>{{memberList.pkMemberForm}}</td>
                        <td>{{memberList.preExcelSheetNo}}</td>
                        <td>{{memberList.name}}</td>
                        <td>{{memberList.mobNo}}</td>
                        <td>{{memberList.designation}}</td>
                        <td>{{memberList.chequeNo}}</td>
                    </tr>
                </ng-container>
            </tbody>

        </table>

    </div>
</div>