import { Directive ,HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appAppAlphabetOnly]'
})
export class AppAlphabetOnlyDirective {

  key;
  @HostListener('keydown', ['$event']) onKeydown(event: KeyboardEvent) {
    this.key = event.keyCode;
    if ((this.key >=91 && this.key <= 96) || (this.key >= 250) ) {
      event.preventDefault();
    }
  }

}
