<app-sub-banner [title]="Title"></app-sub-banner>
    
    <!-- Start Speaker Area -->
     <section class="speaker-section pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6" >
                    <!-- <div class="single-team">
                        <div class="image">
                            <img src="assets/img/team/1.jpg" alt="image">
                        
                            <ul class="social">
                                <li>
                                    <a href="#" target="_blank">
                                        <i class="bx bxl-facebook"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">
                                        <i class="bx bxl-twitter"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">
                                        <i class="bx bxl-linkedin"></i>
                                    </a>
                                </li>
                            </ul>

                            <div class="content">
                                <h3>Alex Maxwel</h3>
                                <span>Founder</span>
                            </div>

                            <div class="speaker-shape">
                                <img src="assets/img/team/shape.png" alt="image">
                            </div>
                        </div>
                    </div> -->
               <p id="showData"></p>
                </div>

            </div>
        </div>

  
    </section>
    <!-- End Speaker Area -->

