<div class="login-section ptb-30">
    <div class="container" id="mytop">
        <div class="login-form">
            <div class="login-title">
                <h3>Select Month for List</h3>
            </div>
            <form [formGroup]="memberform" id="home" novalidate>
                <div class="form-group col-lg-4" id="ValidityDate">Select Date</div>
                <div class="col-lg-8">
                    <mat-form-field appearance="fill">
                        <mat-label>Choose a date</mat-label>
                        <input matInput [matDatepicker]="picker" id="DateOfJoining" placeholder=""
                            formControlName="ChequeDate">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="row inline-block col-12">
                    <div class="col-sm-2">
                        <button type="submit" id="btnsave" class="default-btn" (click)="getMember()">Show</button>
                    </div>


                    <div class="col-sm-2">
                        <button type="button" title="XL" id="btnexcel" class="default-btn" style="width: 130px"><svg
                                xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                class="bi bi-file-earmark-excel" viewBox="0 0 16 16">
                                <path
                                    d="M5.884 6.68a.5.5 0 1 0-.768.64L7.349 10l-2.233 2.68a.5.5 0 0 0 .768.64L8 10.781l2.116 2.54a.5.5 0 0 0 .768-.641L8.651 10l2.233-2.68a.5.5 0 0 0-.768-.64L8 9.219l-2.116-2.54z" />
                                <path
                                    d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                            </svg> Excel</button>
                        &nbsp;
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="container">
    <div class="table-responsive">
        <table class="table" id="tblList">
            <thead>
                <tr>
                    <th>SR.No.</th>
                    <th>MemberID</th>
                    <th>Name</th>
                    <th>Mobile No.</th>
                    <th>Email</th>
                    <th>Member Date</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let memberList of SpResult">
                    <tr>

                        <td>{{memberList.pkMemberForm}}</td>
                        <td>{{memberList.preExcelSheetNo}}</td>
                        <td>{{memberList.name}}</td>
                        <td>{{memberList.mobNo}}</td>
                        <td>{{memberList.email}}</td>
                        <td>{{memberList.memberDate}}</td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>