<section>
    <div class="container">
        <div class="Wrapper">
            <div class="register-form">
         <h3 style="color: black;text-align: center;font-style: normal;">Add Video Gallary</h3>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
   
     <form [formGroup]="VideoGallaryform">
   
       <div class="form-group row">
         <label class="col-sm-3 col-form-label">ID</label>
         <div class="col-sm-7">
           <input  class="form-control" formControlName="PkVideo">
         </div>
       </div>

       <div class="form-group row">
        <label class="col-sm-3 col-form-label">Name</label>
        <div class="col-sm-7">
          <input  class="form-control" formControlName="Name">
        </div>
      </div>
   
       <div class="form-group row">
         <label for="inputPassword" class="col-sm-3 col-form-label">Video Gallary</label>
       <div class="col-sm-7">
             <!-- <ck-editor name="editor1" [(ngModel)]="editorValue" formControlName="VideoUpload"
             skin="moono-lisa" lang="en" [fullPage]="true"></ck-editor> -->
             <textarea name="textarea" class="form-control" formControlName="VideoUpload" ></textarea>
            
       </div>
       </div>
     
       <div class="row">
        <div class="col-sm-2"  >
                 <button type="submit" class="btn btn-primary"           
                          (click)="VideoGallery_Insert()">Submit</button>
        </div>&nbsp;&nbsp;&nbsp;

       <div class="col-sm-2"  >
                  <button type="submit" class="btn btn-primary" 
                          (click)="VideoGallery_Update()">update</button>
       </div>&nbsp;&nbsp;&nbsp;

    <div  class="col-sm-2" >
                    <button type="submit" class="btn btn-primary" 
                               (click)="VideoGallery_Delete()">Delete</button>
       </div>&nbsp;&nbsp;&nbsp;

       <div  class="col-sm-2" >
                    <button type="submit" class="btn btn-primary" 
                              (click)="VideoGallery_Select()">List</button>
       </div>&nbsp;&nbsp;&nbsp;

       <div  class="col-sm-2" >
                      <button type="submit" class="btn btn-primary" 
                                (click)="Reset()">Reset</button>
        </div>&nbsp;&nbsp;&nbsp;
   </div>
     
      
     </form>
    </div>
  </div>
 </div>

</section>

<div class="container">    
    <div class="table-responsive">   
    <table class="table" id="tblList">
       <thead>
            <tr>
               <th>ID</th>
               <th> Name</th>
               <th>Video Path</th>
            </tr>
      </thead>
      <tbody>
           <tr *ngFor="let cmp of CategoryList;">
              <td (click)="RowSelected(cmp)">{{cmp.id}}</td>
              <td>{{cmp.videoName}}</td>
              <td >{{cmp.videoPath}}</td>
           </tr> 
      </tbody>
    </table>
  </div>
</div>



